import {
  AttachmentItem,
  AttachmentList,
  CloseButton,
  FormContainer,
  FormGroup,
  Input,
  Label,
  ModalContent,
  Overlay,
  RemoveButton,
  ScrollableContent,
  SubmitButton,
  TabButton,
  TabContainer,
  TextArea,
} from '../SuggestMoreActivities/styles';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import {
  AddCustomActivityData,
  AddCustomActivityVariables,
  ADD_CUSTOM_ACTIVITY,
} from '../../../../../../graphql/mutations/addCustomActivity';
import {
  AddCustomActivityAttachmentData,
  AddCustomActivityAttachmentVariables,
  ADD_CUSTOM_ACTIVITY_ATTACHMENT,
} from '../../../../../../graphql/mutations/addCustomActivityAttachment';
import { GET_CUSTOM_ACTIVITIES_FOLDER_PATH } from '../../../../../../helpers/functions';
import { Consultation_consultation_child_goals_preskills } from '../../../../../../graphql/queries/types/Consultation';
import Uploader from '../../../../../../util/Uploader';
import { useTranslation } from 'react-i18next';

interface SuggestMoreActivitiesProps {
  skill: Consultation_consultation_child_goals_preskills;
  assignCustomActivityToChild: any;
}

interface Attachment {
  type: 'file' | 'url';
  name: string;
  file?: File;
  url?: string;
  uploadProgress?: number;
}

const SuggestMoreActivities: React.FC<SuggestMoreActivitiesProps> = ({ skill, assignCustomActivityToChild }) => {
  const { t } = useTranslation();
  const [addCustomActivity] = useMutation<AddCustomActivityData, AddCustomActivityVariables>(ADD_CUSTOM_ACTIVITY);
  const [addCustomActivityAttachment] = useMutation<
    AddCustomActivityAttachmentData,
    AddCustomActivityAttachmentVariables
  >(ADD_CUSTOM_ACTIVITY_ATTACHMENT);

  const [isModalOpen, setModalOpen] = useState(false);
  const [activityName, setActivityName] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [parentInteraction, setParentInteraction] = useState('');
  const [attachmentType, setAttachmentType] = useState<'file' | 'url'>('file');
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [urlError, setUrlError] = useState<string>('');

  const isValidUrl = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);
      // Check if protocol is http or https and has a valid domain
      return (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') && parsedUrl.hostname.includes('.');
    } catch {
      return false;
    }
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const uploadFileWithProgress = async (
    file: File,
    skillId: string,
    skillName: string,
    customActivityName: string,
    uniqueFileName: string,
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const folderPath = GET_CUSTOM_ACTIVITIES_FOLDER_PATH(skillId, skillName, customActivityName);

      // Update attachment's upload progress
      const updateAttachmentProgress = (progress: number) => {
        setAttachments((prev) =>
          prev.map((attachment) =>
            attachment.name === file.name ? { ...attachment, uploadProgress: progress } : attachment,
          ),
        );
      };

      // Custom uploader with progress tracking
      Uploader(file, folderPath, uniqueFileName)
        .on('httpUploadProgress', function (evt) {
          const uploaded = Math.round((evt.loaded / evt.total) * 100);
          updateAttachmentProgress(uploaded);
        })
        .send((err, data) => {
          if (err) {
            updateAttachmentProgress(0);
            reject(err);
          } else {
            updateAttachmentProgress(100);
            resolve(data);
          }
        });
    });
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      const fileAttachments = attachments.filter((a) => a.type === 'file' && a.file);
      const uploadedFileNames: string[] = [];

      if (!activityName) {
        alert(t('therapist.consultationScreen.activities.suggestMoreActivities.enterActivityName'));
        setIsUploading(false);
        return;
      }

      // Upload files first
      for (const attachment of fileAttachments) {
        if (attachment.file) {
          try {
            const uniqueFileName = `${Date.now()}_${attachment.file.name}`;
            await uploadFileWithProgress(attachment.file, skill.id, skill.name, activityName, uniqueFileName);
            uploadedFileNames.push(uniqueFileName);
          } catch (uploadError) {
            console.error(`Error uploading attachment ${attachment.name}:`, uploadError);
            alert(
              t('therapist.consultationScreen.activities.suggestMoreActivities.errorFailedToUpload', {
                attachmentName: attachment.name,
              }),
            );
            setIsUploading(false);
            return;
          }
        }
      }

      // Create custom activity
      const { data: customActivityData } = await addCustomActivity({
        variables: {
          skillId: skill.id,
          name: activityName,
          introduction,
          parentInteraction,
        },
      });

      const customActivityId = customActivityData?.addCustomActivity?.customActivity?.id;
      const customActivityName = customActivityData?.addCustomActivity?.customActivity?.name;

      if (customActivityData?.addCustomActivity?.returnMessage?.status === 'SUCCESS' && customActivityId) {
        // Add uploaded files as attachments
        for (const [index, fileName] of uploadedFileNames.entries()) {
          await addCustomActivityAttachment({
            variables: {
              customActivityId: customActivityId as any,
              attachmentType: 'file',
              data: fileName,
            },
          });
        }

        // Add URL attachments
        const urlAttachments = attachments.filter((a) => a.type === 'url' && a.url);
        for (const urlAttachment of urlAttachments) {
          if (urlAttachment.url) {
            await addCustomActivityAttachment({
              variables: {
                customActivityId: customActivityId as any,
                attachmentType: 'url',
                data: urlAttachment.url,
              },
            });
          }
        }

        assignCustomActivityToChild(customActivityId);
        console.log('Custom activity and attachments created successfully');
        closeModal();
      } else {
        console.error('Error creating custom activity:', customActivityData);
      }
    } catch (error) {
      console.error('Mutation error:', error);
      alert(t('therapist.consultationScreen.activities.suggestMoreActivities.errorCreateCustomActivity'));
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setAttachments((prev) => [
        ...prev,
        ...newFiles.map(
          (file) =>
            ({
              type: 'file',
              name: file.name,
              file: file,
              uploadProgress: 0,
            } as Attachment),
        ),
      ]);
    }
  };

  const handleAddUrl = () => {
    if (!attachmentUrl) {
      setUrlError(t('therapist.consultationScreen.activities.suggestMoreActivities.urlRequired'));
      return;
    }

    if (!isValidUrl(attachmentUrl)) {
      setUrlError(t('therapist.consultationScreen.activities.suggestMoreActivities.invalidUrl'));
      return;
    }

    setAttachments((prev) => [...prev, { type: 'url', name: attachmentUrl, url: attachmentUrl }]);
    setAttachmentUrl('');
    setUrlError('');
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div>
      <button
        onClick={openModal}
        style={{
          padding: '10px 20px',
          backgroundColor: '#008080',
          color: 'white',
          border: 'none',
          borderRadius: '6px',
          cursor: 'pointer',
        }}
      >
        {t('therapist.consultationScreen.activities.suggestMoreActivities.assignCustomActivity')}
      </button>

      {isModalOpen && (
        <Overlay>
          <ModalContent>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            <h1 style={{ color: '#008080', marginBottom: '10px' }}>
              {t('therapist.consultationScreen.activities.suggestMoreActivities.assignCustomActivity')}
            </h1>
            <ScrollableContent>
              <FormContainer onSubmit={handleFormSubmit}>
                <FormGroup>
                  <Label>
                    {t('therapist.consultationScreen.activities.suggestMoreActivities.activitySkill')}: {skill.name}
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label required>
                    {t('therapist.consultationScreen.activities.suggestMoreActivities.activityName')}:
                  </Label>
                  <Input
                    type="text"
                    value={activityName}
                    onChange={(e) => setActivityName(e.target.value)}
                    required
                    disabled={isUploading}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>{t('therapist.consultationScreen.activities.suggestMoreActivities.attachmentType')}:</Label>
                  <TabContainer>
                    <TabButton
                      active={attachmentType === 'file'}
                      onClick={(e) => {
                        e.preventDefault();
                        setAttachmentType('file');
                      }}
                      disabled={isUploading}
                    >
                      {t('therapist.consultationScreen.activities.suggestMoreActivities.fileTab')}
                    </TabButton>
                    <TabButton
                      active={attachmentType === 'url'}
                      onClick={(e) => {
                        e.preventDefault();
                        setAttachmentType('url');
                      }}
                      disabled={isUploading}
                    >
                      {t('therapist.consultationScreen.activities.suggestMoreActivities.urlTab')}
                    </TabButton>
                  </TabContainer>
                </FormGroup>

                {attachmentType === 'file' ? (
                  <FormGroup>
                    <Label required>
                      {t('therapist.consultationScreen.activities.suggestMoreActivities.fileUpload')} (JPEG, PNG, PDF):
                    </Label>
                    <Input
                      type="file"
                      accept=".jpeg,.jpg,.png,.pdf"
                      multiple
                      onChange={handleFileChange}
                      disabled={isUploading}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label required>
                      {t('therapist.consultationScreen.activities.suggestMoreActivities.attachmentUrl')}:
                    </Label>
                    <Input
                      type="url"
                      placeholder="Enter URL"
                      value={attachmentUrl}
                      onChange={(e) => {
                        setAttachmentUrl(e.target.value);
                        setUrlError(''); // Clear error when input changes
                      }}
                      disabled={isUploading}
                      style={{ borderColor: urlError ? 'red' : undefined }}
                    />
                    {urlError && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{urlError}</div>}

                    <button
                      type="button"
                      onClick={handleAddUrl}
                      disabled={isUploading}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#008080',
                        color: 'white',
                        border: 'none',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}
                    >
                      {t('therapist.consultationScreen.activities.suggestMoreActivities.addUrl')}
                    </button>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>
                    {t('therapist.consultationScreen.activities.suggestMoreActivities.uploadedAttachments')}:
                  </Label>
                  <AttachmentList>
                    {attachments.map((attachment, index) => (
                      <AttachmentItem key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <span style={{ flexGrow: 1, marginRight: '10px' }}>{attachment.name}</span>

                          {/* Progress Bar for File Uploads */}
                          {attachment.type === 'file' && attachment.uploadProgress !== undefined && (
                            <div
                              style={{
                                width: '100px',
                                height: '10px',
                                backgroundColor: '#e0e0e0',
                                borderRadius: '5px',
                                overflow: 'hidden',
                                marginRight: '10px',
                              }}
                            >
                              <div
                                style={{
                                  width: `${attachment.uploadProgress}%`,
                                  height: '100%',
                                  backgroundColor: attachment.uploadProgress === 100 ? '#4CAF50' : '#008080',
                                  transition: 'width 0.5s ease-in-out',
                                }}
                              />
                            </div>
                          )}

                          {/* Progress Percentage for File Uploads */}
                          {attachment.type === 'file' && attachment.uploadProgress !== undefined && (
                            <span
                              style={{
                                width: '40px',
                                textAlign: 'right',
                                color: attachment.uploadProgress === 100 ? '#4CAF50' : '#008080',
                                fontWeight: 'bold',
                              }}
                            >
                              {attachment.uploadProgress}%
                            </span>
                          )}

                          <RemoveButton
                            onClick={() => handleRemoveAttachment(index)}
                            disabled={
                              isUploading ||
                              (attachment.type === 'file' &&
                                attachment.uploadProgress !== undefined &&
                                attachment.uploadProgress > 0 &&
                                attachment.uploadProgress < 100)
                            }
                          >
                            {t('therapist.consultationScreen.activities.suggestMoreActivities.removeButton')}
                          </RemoveButton>
                        </div>
                      </AttachmentItem>
                    ))}
                  </AttachmentList>
                </FormGroup>

                <FormGroup>
                  <Label>{t('therapist.consultationScreen.activities.suggestMoreActivities.summary')}:</Label>
                  <TextArea
                    value={introduction}
                    onChange={(e) => setIntroduction(e.target.value)}
                    rows={6}
                    placeholder={t('therapist.consultationScreen.activities.suggestMoreActivities.summaryPlaceholder')}
                    disabled={isUploading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t('therapist.consultationScreen.activities.suggestMoreActivities.interactionTactic')}:</Label>
                  <TextArea
                    value={parentInteraction}
                    onChange={(e) => setParentInteraction(e.target.value)}
                    rows={4}
                    placeholder={t(
                      'therapist.consultationScreen.activities.suggestMoreActivities.interactionTacticPlaceholder',
                    )}
                    disabled={isUploading}
                  />
                </FormGroup>
                <SubmitButton type="submit" disabled={isUploading}>
                  {isUploading
                    ? t('therapist.consultationScreen.activities.suggestMoreActivities.uploading')
                    : t('therapist.consultationScreen.activities.suggestMoreActivities.submit')}
                </SubmitButton>
              </FormContainer>
            </ScrollableContent>
          </ModalContent>
        </Overlay>
      )}
    </div>
  );
};

export default SuggestMoreActivities;
