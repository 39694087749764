import React, { createContext } from 'react';
import { ReturnMessage } from '../../../types';
import {
  Consultation_consultation,
  Consultation_consultation_notes,
} from '../../../graphql/queries/types/Consultation';
import {
  Category_categories_subcategories_skills,
  Category_categories,
  Category_categories_subcategories,
} from '../../../graphql/queries/types/Category';
import { Preskills_getPreskillList } from '../../../graphql/queries/types/Preskills';
import { GetActivitiesFromPreskills_getActivitiesFromPreskills } from '../../../graphql/queries/types/GetActivitiesFromPreskills';
import { GenderEnum } from '../../../types/graphql-global-types';

interface Props {
  consultation: Consultation_consultation;
  notes: Consultation_consultation_notes;
  setNotes: React.Dispatch<React.SetStateAction<Consultation_consultation_notes>>;
  activeItem: string;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  newGoal: boolean;
  setNewGoal: React.Dispatch<React.SetStateAction<boolean>>;
  newHomework: boolean;
  setNewHomework: React.Dispatch<React.SetStateAction<boolean>>;
  category: Category_categories | null;
  setCategory: React.Dispatch<React.SetStateAction<Category_categories | null>>;
  subCategory: Category_categories_subcategories | null;
  setSubCategory: React.Dispatch<React.SetStateAction<Category_categories_subcategories | null>>;
  skill: Category_categories_subcategories_skills | null;
  setSkill: React.Dispatch<React.SetStateAction<Category_categories_subcategories_skills | null>>;
  selectedPreskills: Preskills_getPreskillList[];
  setSelectedPreskills: React.Dispatch<React.SetStateAction<Preskills_getPreskillList[]>>;
  selectedActivities: GetActivitiesFromPreskills_getActivitiesFromPreskills[];
  setSelectedActivities: React.Dispatch<React.SetStateAction<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>>;
  returnMessage?: ReturnMessage;
  setReturnMessage: React.Dispatch<React.SetStateAction<ReturnMessage | undefined>>;
  pollingCount: number;
  setPollingCount: React.Dispatch<React.SetStateAction<number>>;
}

const defaultContext: Props = {
  consultation: {
    id: '',
    consultationTime: '',
    canceled: false,
    accepted: false,
    published: false,
    child: {
      id: '',
      name: '',
      gender: GenderEnum.male,
      dob: '',
      childSixMonthGoal: {
        id: '',
        description: '',
      },
      consultations: {
        id: '',
        consultationTime: '',
        notes: {
          id: '',
          mainComplaint: '',
          internalNotes: '',
        },
      },
      client: {
        id: '',
        user: {
          id: '',
          uuid: '',
          name: '',
          email: '',
        },
      },
      product: {
        id: '',
        name: '',
        description: '',
        owner: '',
        maxConsultation: 6,
        productTypesName: 'test',
      },
      completedTasks: 0,
      lastAssignedActivities: [],
      assignedActivities: [],
      assignedCustomActivities: [],
      lastAssignedCustomActivities: [],
      activityChildren: [],
      customActivityChildren: [],
      goals: [],
      remainingHomeworks: [],
      previousConsultation: null,
      pictureUrl: null,
    },
    therapist: {
      id: '',
      consultationUrl: '',
      cnConsultationUrl: '',
      user: {
        id: '',
        name: '',
        locale: '',
      },
    },
    notes: {
      id: '',
      mainComplaint: '',
      internalNotes: '',
      commentForParents: '',
    },
  },
  activeItem: 'Notes',
  setActiveItem: () => {},
  notes: { id: '', mainComplaint: '', internalNotes: '', commentForParents: '' },
  setNotes: () => {},
  newGoal: false,
  setNewGoal: () => {},
  newHomework: false,
  setNewHomework: () => {},
  category: null,
  setCategory: () => {},
  subCategory: null,
  setSubCategory: () => {},
  skill: null,
  setSkill: () => {},
  selectedPreskills: [],
  setSelectedPreskills: () => {},
  selectedActivities: [],
  setSelectedActivities: () => {},
  returnMessage: undefined,
  setReturnMessage: () => {},
  pollingCount: 0,
  setPollingCount: () => {},
};

export default createContext(defaultContext);
