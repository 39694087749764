import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';
import { CustomActivity } from '../queries/types/CustomActivities';

export const ADD_CUSTOM_ACTIVITY_REJECT_MUTATION = gql`
  mutation AddCustomActivityReject($customActivityId: ID!, $childId: ID!, $reason: String!) {
    addCustomActivityReject(customActivityId: $customActivityId, childId: $childId, reason: $reason) {
      success
      customActivityReject {
        id
        customActivity {
          id
          name
        }
        child
        reason
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface AddCustomActivityRejectData {
  addCustomActivityReject: {
    customActivityReject: {
      id: number;
      customActivity: CustomActivity;
      child: string;
      reason: string;
    };
    returnMessage: ReturnMessage;
    success: boolean;
  };
}

export interface AddCustomActivityRejectVariables {
  customActivityId: string;
  childId: string;
  reason: string;
}
