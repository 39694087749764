import { Row, Col } from 'antd';
import React from 'react';
import ProgressBarComponent from '../../ProgressBarComponent';
import { useTranslation } from 'react-i18next';

export const TotalCheckInsActivity = ({
  assignedActivities,
  assignedCustomActivities,
  consultation,
  handleDropDownClick,
}) => {
  const { t } = useTranslation();

  const totalDenominator = (assignedActivities.length + assignedCustomActivities.length) * 20;

  const curSkillLastAssignedActivities = consultation.child.assignedActivities.filter((a) =>
    assignedActivities.some((b) => a.id === b.activity.id),
  );

  const totalActivitiesNumerator = curSkillLastAssignedActivities.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject.checkInCount ?? 0);
  }, 0);

  const curSkillLastAssignedCustomActivities = consultation.child.assignedCustomActivities.filter((a) =>
    assignedCustomActivities.some((b) => a.id === b.customActivity.id),
  );

  const totalCustomActivitiesNumerator = curSkillLastAssignedCustomActivities.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject.checkInCount ?? 0);
  }, 0);

  const totalNumerator = totalActivitiesNumerator + totalCustomActivitiesNumerator;

  return (
    <div onClick={handleDropDownClick} style={{ marginBottom: '20px' }}>
      <Row>
        <Col span={24}>{t('therapist.consultationScreen.totalCheckIns')}</Col>
        <Col span={24} style={{ margin: '8px 0 0 0' }}>
          <ProgressBarComponent value={totalNumerator} maxValue={totalDenominator} overall={true} />
        </Col>
      </Row>
    </div>
  );
};
