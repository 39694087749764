import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';

export const ADD_CUSTOM_ACTIVITY_ATTACHMENT = gql`
  mutation AddCustomActivityAttachment($customActivityId: ID!, $attachmentType: String!, $data: String!) {
    addCustomActivityAttachment(customActivityId: $customActivityId, attachmentType: $attachmentType, data: $data) {
      customActivityAttachment {
        id
        attachmentType
        data
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface CustomActivityAttachment {
  id: number;
  attachmentType: string;
  data: string;
}

export interface AddCustomActivityAttachmentData {
  addCustomActivityAttachment: {
    customActivityAttachment: CustomActivityAttachment;
    returnMessage: ReturnMessage;
  };
}

export interface AddCustomActivityAttachmentVariables {
  customActivityId: number;
  attachmentType: string;
  data: string;
}
