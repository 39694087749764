import React, { useContext, useState, ReactNode, useEffect } from 'react';
import TherapistConsultationScreenGoalsCardComponent from './GoalsCardComponent';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import TherapistConsultationScreenNavArrows from '../NavArrows';
import { useMutation } from 'react-apollo';
import {
  Consultation_consultation_child_activityChildren,
  Consultation_consultation_child_customActivityChildren,
  Consultation_consultation_child_goals,
  Consultation_consultation_child_lastAssignedActivities,
  Consultation_consultation_child_lastAssignedCustomActivities,
} from '../../../../graphql/queries/types/Consultation';
import { useTranslation } from 'react-i18next';
import { ADD_ACTIVITIES_TO_CONSULTATION } from '../../../../graphql/mutations/addActivitiesToConsultation';
import {
  AddActivitiesToConsultation,
  AddActivitiesToConsultationVariables,
} from '../../../../graphql/mutations/types/AddActivitiesToConsultation';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';

import { RoundBorderButton, BigTitle, Text, PopUp } from './StyledComponents';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';
import LoadingOverlay from './LoadingOverlay';
import { ADD_CUSTOM_ACTIVITIES_TO_CONSULTATION } from '../../../../graphql/mutations/addCustomActivitiesToConsultation';
import {
  AddCustomActivitiesToConsultation,
  AddCustomActivitiesToConsultationVariables,
} from '../../../../graphql/mutations/types/AddCustomActivitiesToConsultation';

const MARGIN_HEIGHT = 5;

const TitleFontSize = '13px';
const TitleFontWeight = 400;

const MIN_SELECTED_ACTIVITY_COUNT = 5;
const MAX_SELECTED_ACTIVITY_COUNT = 8;
const MIN_NEW_SELECTED_ACTIVITY_COUNT = 3;

const TherapistConsultationActivitiesSelectionComponent = () => {
  const {
    consultation,
    consultation: {
      child: {
        product: { productTypesName },
      },
    },
    setActiveItem,
    category,
    setCategory,
    setSubCategory,
    skill,
    setSkill,
    pollingCount,
  } = useContext(TherapistConsultationScreenContext);

  const [addActivitiesToConsultation] = useMutation<AddActivitiesToConsultation, AddActivitiesToConsultationVariables>(
    ADD_ACTIVITIES_TO_CONSULTATION,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
    },
  );

  const [addCustomActivitiesToConsultation] = useMutation<
    AddCustomActivitiesToConsultation,
    AddCustomActivitiesToConsultationVariables
  >(ADD_CUSTOM_ACTIVITIES_TO_CONSULTATION, {
    refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
  });

  const { t } = useTranslation();

  const [lastConsultationActivities, setLastConsultationActivities] = useState<
    Consultation_consultation_child_lastAssignedActivities[]
  >([]);
  const [thisConsultationActivities, setThisConsultationActivities] = useState<any[]>([]);
  const [lastConsultationCustomActivities, setLastConsultationCustomActivities] = useState<
    Consultation_consultation_child_lastAssignedCustomActivities[]
  >([]);
  const [thisConsultationCustomActivities, setThisConsultationCustomActivities] = useState<any[]>([]);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const [achievedGoals, setAchievedGoals] = useState<Consultation_consultation_child_goals[]>(
    consultation.child.goals.filter((g) => g.achieved),
  );
  const [onGoingGoals, setOnGoingGoals] = useState<Consultation_consultation_child_goals[]>(
    consultation.child.goals
      .filter((g) => !g.achieved)
      .slice()
      .reverse(),
  );

  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const isCPC = CPC_PRODUCT_TYPES.includes(productTypesName);

  useEffect(() => {
    setLastConsultationActivities(consultation.child.lastAssignedActivities);
    setThisConsultationActivities(consultation.child.activityChildren);
    setLastConsultationCustomActivities(consultation.child.lastAssignedCustomActivities);
    setThisConsultationCustomActivities(consultation.child.customActivityChildren);
  }, [consultation]);

  const setGoalAchieved = (id: string) => {
    const goal = onGoingGoals.find((g) => g.id == id);
    if (!goal) {
      return;
    }

    goal.achieved = true;
    setAchievedGoals([...achievedGoals, goal]);
    setOnGoingGoals(onGoingGoals.filter((g) => g.id !== id));
  };

  const updateGoal = (goal: Consultation_consultation_child_goals) => {
    setOnGoingGoals(
      onGoingGoals.map((g: Consultation_consultation_child_goals) => {
        return g.id == goal.id ? goal : g;
      }),
    );
  };

  const setGoalDeleted = (id: string) => {
    setOnGoingGoals(onGoingGoals.filter((g) => g.id !== id));
  };

  const handleClickBack = () => {
    if (skill) {
      setSkill(null);
      return;
    }
    if (category) {
      setCategory(null);
      setSubCategory(null);
      return;
    }
    setActiveItem('Progress');
  };

  const handleClickNext = async () => {
    if (isCPC) {
      if (pollingCount === 0) setIsPopUpOpen(true);
    } else {
      handlePopUpConfirmClick();
    }
  };

  const handleClickAgree = () => {
    setIsAgree(!isAgree);
  };

  const handlePopUpClickClose = () => {
    setIsPopUpOpen(false);
  };

  const PopUpTextBox = styled.div`
    width: 425px;
  `;

  const BigTitleDiv = ({ children }: { children: ReactNode }) => {
    return <BigTitle style={{ textAlign: 'center', margin: `${MARGIN_HEIGHT}px 0 0 0` }}>{children}</BigTitle>;
  };

  const TextDiv = ({ children }: { children: ReactNode }) => {
    return <Text style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>{children}</Text>;
  };

  const handlePopUpConfirmClick = async () => {
    await addActivitiesToConsultation({
      variables: {
        activityIds: consultation.child.activityChildren.map(
          (ac: Consultation_consultation_child_activityChildren) => ac.activity.id,
        ),
        consultationId: consultation.id,
      },
    });
    await addCustomActivitiesToConsultation({
      variables: {
        customActivityIds: consultation.child.customActivityChildren.map(
          (cac: Consultation_consultation_child_customActivityChildren) => cac.customActivity.id,
        ),
        consultationId: consultation.id,
      },
    });

    setActiveItem('Plan');
    handlePopUpClickClose();
  };

  const Confirm = () => {
    return (
      <Row justify="center" style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
        {isAgree ? (
          <RoundBorderButton onClick={handlePopUpConfirmClick} style={{}}>
            {t('therapist.consultationScreen.confirm')}
          </RoundBorderButton>
        ) : (
          <RoundBorderButton style={{ backgroundColor: 'lightgrey', cursor: 'default' }}>
            {t('therapist.consultationScreen.confirm')}
          </RoundBorderButton>
        )}
      </Row>
    );
  };

  const LastWeekThisWeekActivityDiff = () => {
    const lastConsultationActivityNames = lastConsultationActivities.map((a) => a.name);
    const thisConsultationActivityNames = thisConsultationActivities.map((a) => a.activity.name);
    const lastConsultationCustomActivityNames = lastConsultationCustomActivities.map((a) => a.name);
    const thisConsultationCustomActivityNames = thisConsultationCustomActivities.map((a) => a.customActivity.name);

    const removedActivityNames = lastConsultationActivityNames.filter(
      (a) => !thisConsultationActivityNames.includes(a),
    );
    const removedCustomActivityNames = lastConsultationCustomActivityNames.filter(
      (a) => !thisConsultationCustomActivityNames.includes(a),
    );

    const noChangeActivityNames = lastConsultationActivityNames.filter((a) =>
      thisConsultationActivityNames.includes(a),
    );
    const noChangeCustomActivityNames = lastConsultationCustomActivityNames.filter((a) =>
      thisConsultationCustomActivityNames.includes(a),
    );

    const addedActivityNames = thisConsultationActivityNames.filter((a) => !lastConsultationActivityNames.includes(a));
    const addedCustomActivityNames = thisConsultationCustomActivityNames.filter(
      (a) => !lastConsultationCustomActivityNames.includes(a),
    );

    const activitiesWithChange: JSX.Element[] = [];

    const previousConsultationCount = consultation.child.consultations
      .filter(
        (c) => c.notes && c.notes?.mainComplaint !== 'Auto Generated' && c.notes?.internalNotes !== 'Auto Generated',
      )
      .filter((c) => c.id < consultation.id).length;

    const maxChangeLength = Math.max(
      removedActivityNames.length + removedCustomActivityNames.length,
      addedActivityNames.length + addedCustomActivityNames.length,
    );

    for (let i = 0; i < maxChangeLength; i++) {
      const element =
        previousConsultationCount !== 0 ? (
          <Row key={i}>
            <Col span={12}>
              <TextDiv>
                {i < removedActivityNames.length && (
                  <div style={{ textDecoration: 'line-through', color: 'red' }}>{removedActivityNames[i]}</div>
                )}
                {i < removedCustomActivityNames.length && (
                  <div style={{ textDecoration: 'line-through', color: 'red' }}>{removedCustomActivityNames[i]}</div>
                )}
              </TextDiv>
            </Col>
            <Col span={12}>
              <TextDiv>
                {i < addedActivityNames.length && <div style={{ color: 'green' }}>{addedActivityNames[i]}</div>}
                {i < addedCustomActivityNames.length && (
                  <div style={{ color: 'green' }}>{addedCustomActivityNames[i]}</div>
                )}
              </TextDiv>
            </Col>
          </Row>
        ) : (
          <Row key={i}>
            <Col span={24}>
              <TextDiv>
                {i < addedActivityNames.length && <div style={{ color: 'green' }}>{addedActivityNames[i]}</div>}
                {i < addedCustomActivityNames.length && (
                  <div style={{ color: 'green' }}>{addedCustomActivityNames[i]}</div>
                )}
              </TextDiv>
            </Col>
          </Row>
        );

      activitiesWithChange.push(element);
    }

    return (
      <div style={{ padding: '15px 0px 15px 35px', border: '1px solid none' }}>
        <Col style={{ border: '1px solid none' }}>
          {previousConsultationCount !== 0 ? (
            <Row>
              <Col span={12}>
                <TextDiv>
                  <div
                    style={{
                      color: 'teal',
                      fontSize: TitleFontSize,
                      textDecoration: 'underline',
                      fontWeight: TitleFontWeight,
                    }}
                  >
                    {t('therapist.consultationScreen.activityConfirmation.lastPlan')}
                  </div>
                </TextDiv>
              </Col>
              <Col span={12}>
                <TextDiv>
                  <div
                    style={{
                      color: 'teal',
                      fontSize: TitleFontSize,
                      textDecoration: 'underline',
                      fontWeight: TitleFontWeight,
                    }}
                  >
                    {t('therapist.consultationScreen.activityConfirmation.thisPlan')}
                  </div>
                </TextDiv>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={24}>
                <TextDiv>
                  <div
                    style={{
                      color: 'teal',
                      fontSize: TitleFontSize,
                      textDecoration: 'underline',
                      fontWeight: TitleFontWeight,
                    }}
                  >
                    {t('therapist.consultationScreen.activityConfirmation.thisPlan')}
                  </div>
                </TextDiv>
              </Col>
            </Row>
          )}

          {previousConsultationCount !== 0 && (
            <>
              {noChangeActivityNames.map((a, index) => (
                <Row key={`unchanged-activity-${index}`}>
                  <Col span={12}>
                    <TextDiv>{<div style={{ fontWeight: 'lighter' }}>{a}</div>}</TextDiv>
                  </Col>
                  <Col span={12}>
                    <TextDiv>{<div style={{ fontWeight: 'lighter' }}>{a}</div>}</TextDiv>
                  </Col>
                </Row>
              ))}
              {noChangeCustomActivityNames.map((a, index) => (
                <Row key={`unchanged-custom-activity-${index}`}>
                  <Col span={12}>
                    <TextDiv>{<div style={{ fontWeight: 'lighter' }}>{a}</div>}</TextDiv>
                  </Col>
                  <Col span={12}>
                    <TextDiv>{<div style={{ fontWeight: 'lighter' }}>{a}</div>}</TextDiv>
                  </Col>
                </Row>
              ))}
            </>
          )}

          {activitiesWithChange}

          <Divider style={{ margin: '5px 0 3px -10px' }} />

          {previousConsultationCount !== 0 ? (
            <Row>
              <Col span={12}>
                <TextDiv>
                  {`${t('therapist.consultationScreen.activityConfirmation.total')}: ${
                    lastConsultationActivityNames.length + lastConsultationCustomActivityNames.length
                  }`}
                </TextDiv>
              </Col>
              <Col span={12}>
                <TextDiv>
                  {`${t('therapist.consultationScreen.activityConfirmation.total')}: ${
                    thisConsultationActivityNames.length + thisConsultationCustomActivityNames.length
                  }`}
                </TextDiv>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={24}>
                <TextDiv>
                  {`${t('therapist.consultationScreen.activityConfirmation.total')}: ${
                    thisConsultationActivityNames.length + thisConsultationCustomActivityNames.length
                  }`}
                </TextDiv>
              </Col>
            </Row>
          )}
        </Col>

        {addedActivityNames.length + addedCustomActivityNames.length < MIN_NEW_SELECTED_ACTIVITY_COUNT && (
          <TextDiv>
            <div style={{ display: 'flex', justifyContent: 'start', color: 'grey', fontStyle: 'italic' }}>
              {t('therapist.consultationScreen.task.overview.tooLittleNewActivitesSelected', {
                newSelectedActivitiesCount: addedActivityNames.length + addedCustomActivityNames.length,
                minNewSelectedActivitiesCount: MIN_NEW_SELECTED_ACTIVITY_COUNT,
              })}
            </div>
          </TextDiv>
        )}
      </div>
    );
  };

  const filteredConsultations =
    consultation.child.consultations.length > 0
      ? consultation.child.consultations.filter((c) => {
          return c.notes !== null;
        })
      : [];

  const isLastConsultation = filteredConsultations.length >= consultation.child.product.maxConsultation;

  const isEligibleToProceed =
    isLastConsultation ||
    (thisConsultationActivities.length + thisConsultationCustomActivities.length >= MIN_SELECTED_ACTIVITY_COUNT &&
      thisConsultationActivities.length + thisConsultationCustomActivities.length <= MAX_SELECTED_ACTIVITY_COUNT);

  return (
    <div>
      {isPopUpOpen && (
        <PopUp onClickClose={handlePopUpClickClose}>
          <PopUpTextBox>
            <BigTitleDiv>
              {t('therapist.consultationScreen.activityConfirmation.areTheseTheActivitiesYouWantToAssign')}
            </BigTitleDiv>
            <LastWeekThisWeekActivityDiff />

            {isEligibleToProceed ? (
              <div>
                <div style={{ display: 'flex' }}>
                  <input
                    style={{ marginRight: '10px' }}
                    type="checkbox"
                    checked={isAgree}
                    onChange={handleClickAgree}
                    data-cy="acceptTerms"
                  />
                  <TextDiv>{t('therapist.consultationScreen.task.overview.readAgreement')}</TextDiv>
                </div>
                <div style={{ marginTop: '8px' }}>
                  <Confirm />
                </div>
              </div>
            ) : (
              <TextDiv>
                {thisConsultationActivities.length + thisConsultationCustomActivities.length <
                  MIN_SELECTED_ACTIVITY_COUNT && (
                  <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    {t('therapist.consultationScreen.task.overview.tooLittleActivitesSelected', {
                      minSelectedActivitiesCount: MIN_SELECTED_ACTIVITY_COUNT,
                    })}
                  </div>
                )}

                {thisConsultationActivities.length + thisConsultationCustomActivities.length >
                  MAX_SELECTED_ACTIVITY_COUNT && (
                  <div style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                    {t('therapist.consultationScreen.task.overview.tooManyActivitesSelected', {
                      maxSelectedActivitiesCount: MAX_SELECTED_ACTIVITY_COUNT,
                    })}
                  </div>
                )}
              </TextDiv>
            )}
          </PopUpTextBox>
        </PopUp>
      )}

      {pollingCount !== 0 && <LoadingOverlay />}

      <TherapistConsultationScreenNavArrows
        onClickBack={() => handleClickBack()}
        onClickNext={() => handleClickNext()}
      />
      <div>
        <p style={{ fontSize: '18px', fontWeight: 'bolder', marginTop: '20px' }}>
          {t('therapist.consultationScreen.task.overview.shortTermActivityPlan')}
        </p>
        <div style={{ margin: '-20px 0 10px 0' }}>
          <p>
            {t('therapist.consultationScreen.task.overview.pleaseSelectActivitiesForTheUpcomingWeeks', {
              minSelectedActivitiesCount: MIN_SELECTED_ACTIVITY_COUNT,
              maxSelectedActivitiesCount: MAX_SELECTED_ACTIVITY_COUNT,
              minNewSelectedActivitiesCount: MIN_NEW_SELECTED_ACTIVITY_COUNT,
            })}
          </p>
        </div>

        <div style={{ marginBottom: '15px' }}>
          {onGoingGoals.map((goal, index) => (
            <TherapistConsultationScreenGoalsCardComponent
              key={goal.id}
              subCategory={goal.skill.subcategory}
              category={goal.skill.category}
              goal={goal}
              index={index}
              isEdit={false}
              updateGoal={updateGoal}
              achieveGoal={setGoalAchieved}
              setGoalDeleted={setGoalDeleted}
              isSelectingActivity={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TherapistConsultationActivitiesSelectionComponent;
