import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import TherapistConsultationScreenContext from './TherapistConsultationScreenContext';
import { useTranslation } from 'react-i18next';
import { GeneralSegment } from '../../../style';

const renderActivityChildrenRow = (activity, assignedActivities) => (
  <Grid.Row key={activity.id}>
    <Grid.Column width={10} textAlign="left">
      {activity.name}
    </Grid.Column>
    <Grid.Column width={6} textAlign="center">
      {assignedActivities.find((a) => a.id === activity.id)?.checkInCount ? (
        <span style={{ color: '#47E4C2' }}>&#10004; </span>
      ) : (
        <span style={{ color: 'red' }}>x </span>
      )}
      {assignedActivities.find((a) => a.id === activity.id)?.checkInCount ?? 0}
    </Grid.Column>
  </Grid.Row>
);

const TherapistConsultationScreenActivitiesComponent = () => {
  const { consultation } = useContext(TherapistConsultationScreenContext);

  const { t } = useTranslation();
  return (
    <GeneralSegment>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={10} textAlign="left">
            <h3>{t('therapist.consultationScreen.thisWeeksTasks')}</h3>
          </Grid.Column>
          <Grid.Column width={6} textAlign="center">
            {t('therapist.consultationScreen.completedTimes')}
          </Grid.Column>
        </Grid.Row>

        {consultation.child.activityChildren.map(({ activity }) =>
          renderActivityChildrenRow(activity, consultation.child.assignedActivities),
        )}

        {consultation.child.customActivityChildren.map(({ customActivity }) =>
          renderActivityChildrenRow(customActivity, consultation.child.assignedCustomActivities),
        )}
      </Grid>
    </GeneralSegment>
  );
};

export default TherapistConsultationScreenActivitiesComponent;
