import React, { useState, useContext, useEffect } from 'react';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import {
  Consultation_consultation_child_activityChildren,
  Consultation_consultation_child_goals_preskills,
  Consultation_consultation_child_goals,
} from '../../../../graphql/queries/types/Consultation';
import ActivityComponent from './ActivityComponent';
import { Col, Row } from 'antd';
import SkillCheckbox from './SkillCheckbox';
import { Icon } from 'semantic-ui-react';
import './DropDownAnimation.css';
import { GoalSkillLabel, PreSkillLabel, VerticalDivider, RoundBorderButton } from './StyledComponents';
import { useTranslation } from 'react-i18next';

const DROPDOWN_COL_WIDTH = 1;
const SKILLNAME_COL_WIDTH = 5;
const CHECKBOX_COL_WIDTH = 4;
interface Props {
  skill: Consultation_consultation_child_goals_preskills;
  goal: Consultation_consultation_child_goals;
  handleGoalAchieved: () => void;
  removeAllActivitesFromGoal: () => void;

  preSkills: Consultation_consultation_child_goals_preskills[];
  isGoalAchievedClicked: boolean;
  setIsGoalAchievedClicked: (isGoalAchievedClicked: boolean) => void;
}

const SkillAndActivitiesComponent = ({
  skill,
  goal,
  handleGoalAchieved,
  removeAllActivitesFromGoal,
  isGoalAchievedClicked,
  setIsGoalAchievedClicked,
}: Props) => {
  const isMainSkill = skill.id === goal.skill.id;

  const [isDropDown, setIsDropDown] = useState<boolean>(true);

  const { t } = useTranslation();

  const handleDropDownClick = () => {
    setIsDropDown(!isDropDown);
  };

  const { consultation } = useContext(TherapistConsultationScreenContext);

  const [activities, setActivities] = useState<Consultation_consultation_child_activityChildren[]>([]);
  const [customActivities, setCustomActivities] = useState<any[]>([]);

  useEffect(() => {
    setActivities(
      consultation.child.activityChildren.filter(
        (activityChildren) => activityChildren.activity.mainSkill.id == skill.id,
      ),
    );
    setCustomActivities(
      consultation.child.customActivityChildren.filter(
        (customActivityChildren) => customActivityChildren.customActivity.skill.id == skill.id,
      ),
    );
  }, [consultation]);

  const GoalSkill = () => {
    return (
      <div>
        <GoalSkillLabel>{t('therapist.consultationScreen.activities.goalSkill')}</GoalSkillLabel>
        <div style={{ height: '4px' }} />
        <div>{skill.name}</div>
      </div>
    );
  };

  const PreSkill = () => {
    return (
      <div>
        <PreSkillLabel>{t('therapist.consultationScreen.activities.preSkill')}</PreSkillLabel>
        <div style={{ height: '4px' }} />
        <div>{skill.name}</div>
      </div>
    );
  };

  const Checkbox = () => {
    return (
      <Row justify="space-evenly">
        <Col>
          <VerticalDivider style={{ margin: '0 10px 0 0' }} />
        </Col>

        <Col>
          <SkillCheckbox
            goal={goal}
            skill={skill}
            handleGoalAchieved={handleGoalAchieved}
            removeAllActivitesFromGoal={removeAllActivitesFromGoal}
            isGoalAchievedClicked={isGoalAchievedClicked}
            setIsGoalAchievedClicked={setIsGoalAchievedClicked}
          ></SkillCheckbox>
        </Col>

        <Col>
          <VerticalDivider style={{ margin: '0 0 0 10px' }} />
        </Col>
      </Row>
    );
  };

  const DropDownArrow = () => {
    const dropDownStyle = {
      transform: 'rotate(0deg)',
      padding: '0 0 25px 0',
      fontSize: '17px',
    };

    const dropDown90Style = {
      transform: 'rotate(-90deg)',
      padding: '0 0 25px 0',
      fontSize: '17px',
    };

    return (
      <div
        style={{
          width: '25px',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '5px 0px 0 0',
          padding: '0 5px 0 0',
        }}
        onClick={handleDropDownClick}
      >
        {(activities.length > 0 || customActivities.length > 0) && (
          <div>
            {isDropDown ? (
              <Icon name="dropdown" style={dropDownStyle} />
            ) : (
              <div className="moving-element">
                <Icon name="dropdown" style={dropDown90Style} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <Row>
      <Col span={SKILLNAME_COL_WIDTH}>{isMainSkill ? <GoalSkill /> : <PreSkill />}</Col>

      <Col span={CHECKBOX_COL_WIDTH}>
        <Checkbox />
      </Col>

      <Col span={DROPDOWN_COL_WIDTH}>
        <DropDownArrow />
      </Col>

      <Col span={24 - SKILLNAME_COL_WIDTH - CHECKBOX_COL_WIDTH - DROPDOWN_COL_WIDTH} style={{ cursor: 'pointer' }}>
        <ActivityComponent
          skill={skill}
          isDropDown={isDropDown}
          handleDropDownClick={handleDropDownClick}
          goal={goal}
        ></ActivityComponent>
      </Col>
    </Row>
  );
};

export default SkillAndActivitiesComponent;
