import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'semantic-ui-react';
import { Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import TherapistInteractionDashboardComponent from '../Communication/components/TherapistInteractionDashboardComponent';
import TimelineComponent from './components/TimelineComponent';
import { ClientContext } from '../ClientContext';
import UserContext from '../../common/UserContext';
import { SectionTitleContainer, ToggleContainer } from './styles';
import { RouteSectionContainer } from '../styles';
import { Description, GeneralSegment, SectionTitle } from '../../../style';
import { CPC_PRODUCT_TYPES } from '../../../util/Product';

interface Props {}

const ConsultationRoute = ({}: Props) => {
  window.scroll(0, 0);
  const [showPreviousConsultations, setShowPreviousConsultations] = useState(false);
  const { t } = useTranslation();
  const { client } = useContext(ClientContext);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const { user } = useContext(UserContext);
  const { locale } = user;
  const productType = child.product.productTypesName;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  const consultationUrl =
    client.user.country == 'cn' ? child.therapist.cnConsultationUrl : child.therapist.consultationUrl;
  const history = useHistory();

  const OpenConsultationHistory = () => {
    setShowPreviousConsultations(!showPreviousConsultations);
  };

  return (
    <RouteSectionContainer>
      {isCPC && (
        <GeneralSegment>
          <SectionTitle>{t('client.consultation.preparationTitle')}</SectionTitle>
          <TherapistInteractionDashboardComponent roomId={child.room.id} therapist={child.therapist} />
        </GeneralSegment>
      )}
      <GeneralSegment>
        <SectionTitleContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SectionTitle>{t('client.consultation.consultationTitle')}</SectionTitle>
            <div
              onClick={() => history.push('/guidelines')}
              style={{ cursor: 'pointer', color: '#4183c4', textDecoration: 'underline', margin: '5px 0' }}
            >
              {t('client.consultation.guidelinesLinkText')}
            </div>
          </div>
          <ToggleContainer>
            <Description style={{ margin: '0px 10px' }}>
              {t('client.communicationCard.showPastConsultations')}
            </Description>
            <Radio toggle onChange={() => OpenConsultationHistory()} />
          </ToggleContainer>
        </SectionTitleContainer>
        <div
          style={{ textAlign: 'justify' }}
          dangerouslySetInnerHTML={{ __html: t('client.smsDropdown.instruction') }}
        ></div>
        {child.externalScheduleLink && (
          <div>
            <a href={child.externalScheduleLink} target="_blank" rel="noopener noreferrer">
              {t('client.consultation.scheduleLink')}
            </a>
          </div>
        )}
        <TimelineComponent
          showPreviousConsultations={showPreviousConsultations}
          clientId={client.id}
          routeLocale={locale}
          routeTimezone={client.timeZone}
          therapistUrl={consultationUrl}
        />
      </GeneralSegment>
    </RouteSectionContainer>
  );
};

export default ConsultationRoute;
