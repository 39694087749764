import React from 'react';
import ActivityCard from '../../ActivityCard';
import { DEFAULT_INDEX } from '../../ActivityComponent';
import { Row } from 'antd';
import { RoundBorderButton } from '../../StyledComponents';
import { useTranslation } from 'react-i18next';
import CustomActivityCard from '../../CustomActivityCard';

export const CUSTOM_ACTIVITY_TYPENAME = 'CustomActivity';

export const RandomActivityComponent = ({
  randomAvailableActivities,
  setSelectedActivityIndex,
  selectedActivityIndex,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxHeight: `${window.innerHeight * 0.8}px`,
        maxWidth: `${window.innerWidth * 0.6 + 35}px`,
        overflow: 'auto',
        margin: '10px 0px',
      }}
    >
      {randomAvailableActivities.map((activity, index) => (
        <div key={index} onClick={() => setSelectedActivityIndex(index)} style={{ cursor: 'default' }}>
          {activity.__typename === CUSTOM_ACTIVITY_TYPENAME ? (
            <CustomActivityCard customActivity={activity} isSelected={selectedActivityIndex === index} />
          ) : (
            <ActivityCard activity={activity} isSelected={selectedActivityIndex === index} />
          )}
        </div>
      ))}
    </div>
  );
};

export const RandomActivityConfirmOrSuggest = ({
  totalAvailableActivities,
  selectedActivityIndex,
  selectedActivity,
  assignActivityToChild,
  assignCustomActivityToChild,
  redirectActivityRecommend,
}) => {
  const { t } = useTranslation();
  const isActivitySelected = selectedActivityIndex != DEFAULT_INDEX;
  const isCustomActivity = isActivitySelected && selectedActivity?.__typename === CUSTOM_ACTIVITY_TYPENAME;

  return (
    <div>
      {totalAvailableActivities > 0 ? (
        <Row justify="center">
          <RoundBorderButton
            onClick={
              isCustomActivity && isActivitySelected
                ? () => assignCustomActivityToChild(selectedActivity?.id)
                : assignActivityToChild
            }
            style={{
              width: '100px',
              fontSize: '14px',
              margin: '5px 0 0 0',
              backgroundColor: isActivitySelected ? 'teal' : 'lightgrey',
              cursor: isActivitySelected ? 'pointer' : 'default',
            }}
          >
            {t('therapist.consultationScreen.confirm')}
          </RoundBorderButton>
        </Row>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ margin: '30px 100px' }}>{t('therapist.consultationScreen.activityPopUp.noActivities')}</div>

          <RoundBorderButton onClick={redirectActivityRecommend} style={{ fontSize: '14px', margin: '8px 0 0 0' }}>
            {t('therapist.consultationScreen.activityPopUp.recommendNewActivity')}
          </RoundBorderButton>
        </div>
      )}
    </div>
  );
};
