import React, { useState } from 'react';
import { LuRefreshCw, LuRefreshCwOff } from 'react-icons/lu';
import { CircleButton } from '../../StyledComponents';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './styles';

const RefreshButton = ({ totalAvailableActivities, refreshRandomAvailableActivities, randomCount }) => {
  const { t } = useTranslation();

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return (
    <div style={{ margin: '0 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {totalAvailableActivities > randomCount ? (
        <div style={{ position: 'relative' }}>
          <CircleButton
            onClick={refreshRandomAvailableActivities}
            style={{
              backgroundColor: 'teal',
              color: 'white',
              borderRadius: '50%',
              padding: '6px', // Adjusted size to match the smaller text
              border: 'none',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <LuRefreshCw />
          </CircleButton>
          {showTooltip && <Tooltip>{t('therapist.consultationScreen.activities.refreshButtonToolTip')}</Tooltip>}
        </div>
      ) : (
        <CircleButton
          style={{ backgroundColor: 'lightgrey', margin: '0 5px', cursor: 'default', padding: '6px' }} // Adjusted size
        >
          <LuRefreshCwOff />
        </CircleButton>
      )}
    </div>
  );
};

export default RefreshButton;
