import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';
import { CustomActivity } from '../queries/types/CustomActivities';

export const REMOVE_CUSTOM_ACTIVITIES_TO_CHILD_MUTATION = gql`
  mutation RemoveCustomActivitiesToChild($customActivityIds: [ID!]!, $childId: ID!, $consultationIds: [ID!]!) {
    removeCustomActivitiesToChild(
      customActivityIds: $customActivityIds
      childId: $childId
      consultationIds: $consultationIds
    ) {
      customActivities {
        id
        name
        introduction
        parentInteraction
        skill {
          id
          name
        }
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface RemoveCustomActivitiesToChildData {
  removeActivitiesToChild: { customActivities: CustomActivity[]; returnMessage: ReturnMessage };
}

export interface RemoveCustomActivitiesToChildVariables {
  customActivityIds: string[];
  childId: string;
  consultationIds: string[];
}
