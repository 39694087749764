import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';
import { CustomActivity } from '../queries/types/CustomActivities';

export const ADD_CUSTOM_ACTIVITIES_TO_CHILD = gql`
  mutation AddCustomActivitiesToChild($customActivityIds: [ID!]!, $childId: ID!, $consultationId: ID!) {
    addCustomActivitiesToChild(
      customActivityIds: $customActivityIds
      childId: $childId
      consultationId: $consultationId
    ) {
      customActivities {
        id
        name
        introduction
        parentInteraction
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface AddCustomActivitiesToChild {
  addCustomActivitiesToChild: { customActivities: CustomActivity[]; returnMessage: ReturnMessage };
}

export interface AddCustomActivitiesToChildVariables {
  customActivityIds: number[];
  childId: string;
  consultationId: string;
}
