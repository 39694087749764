import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';
import { CustomActivity } from '../queries/types/CustomActivities';

export const ADD_CUSTOM_ACTIVITY = gql`
  mutation AddCustomActivity($skillId: ID!, $name: String!, $introduction: String, $parentInteraction: String) {
    addCustomActivity(
      skillId: $skillId
      name: $name
      introduction: $introduction
      parentInteraction: $parentInteraction
    ) {
      customActivity {
        id
        skill {
          id
          name
        }
        introduction
        parentInteraction
        createdAt
        updatedAt
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface AddCustomActivityData {
  addCustomActivity: {
    customActivity: CustomActivity;
    returnMessage: ReturnMessage;
  };
}

export interface AddCustomActivityVariables {
  skillId: string;
  name: string;
  introduction?: string;
  parentInteraction?: string;
}
