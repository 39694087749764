import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import { Row, Col } from 'antd';

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoundBorderLabel = styled(CenterDiv)`
  border: 2px solid teal;
  border-radius: 15px;
  opacity: 0.75;
  font-size: 13px;
  font-weight: 500;
  background-color: white;
  color: teal;
`;

export const GoalSkillLabel = styled(RoundBorderLabel)`
  background-color: teal;
  color: white;
`;

export const PreSkillLabel = styled(RoundBorderLabel)`
  font-size: 12px;
  color: teal;
`;

export const Spacer = styled.div`
  height: 5px;
`;

export const VerticalSpacer = styled.div`
  height: 100%;
  width: 12px;
`;

export const RoundBox = styled.div`
  border: 2px solid teal;
  border-radius: 15px;
  background-color: blue;
  color: teal;
`;

export const VerticalDivider = styled.div`
  background-color: #f1f1f1;
  width: 2px;
  height: 100%;
`;

export const GoalSkillDivider = styled.div`
  margin: 10px 0;
  border-bottom: 3px dotted lightgrey;
  width: 100%;
`;

const h = 30;
const r = 30;

export const RoundBorderButton = styled(CenterDiv)`
  border-radius: ${r}px;
  height: ${h}px;
  font-size: 12px;
  font-weight: 600;
  background-color: teal;
  color: white;
  padding: 0px 12px;
  cursor: pointer;
`;

export const CircleButton = styled(CenterDiv)`
  height: ${h}px;
  width: ${h}px;
  border-radius: ${r}px;
  background-color: teal;
  color: white;
  cursor: pointer;
`;

export const PopUpDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  border-radius: 15px;
  background-color: white;
  padding: 15px;
`;

export const CloseXButton = () => {
  return (
    <CircleButton style={{ backgroundColor: 'lightgrey' }}>
      <IoMdClose />
    </CircleButton>
  );
};

export const BigTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  color: teal;
`;

export const Title = styled.div`
  font-weight: 650;
  font-size: 14px;
`;

export const Text = styled.div`
  font-weight: 300;
  font-size: 13px;
`;

export const TextHiddenEllipsis = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ActivityCardDiv = styled.div`
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin: 10px;
  border-radius: 15px;
`;

export const SuggestChangesText = styled.div`
  margin-top: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 14px;
  color: #666;
  font-style: italic;
  text-align: center;
`;

export const PopUp = ({ onClickClose, children }: { onClickClose: () => void; children: ReactNode }) => {
  return (
    <div>
      <PopUpDiv>
        <Col>
          <div onClick={onClickClose} style={{ position: 'absolute', right: '0px' }}>
            <CloseXButton />
          </div>

          {children}
        </Col>
      </PopUpDiv>
    </div>
  );
};
