import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  width: 600px;
  max-width: 90%;
  max-height: 90vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
  display: flex;
  flex-direction: column;
`;

export const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: 70vh;
  padding-right: 10px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Label = styled.label<{ required?: boolean }>`
  font-weight: bold;
  color: #333;
  ${({ required }) => required && `&::after { content: ' *'; color: red; }`}
`;

export const Legend = styled.legend`
  font-size: 0.9rem; /* Smaller font size */
  margin-bottom: 20px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
`;

export const TabContainer = styled.div`
  display: flex;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
`;

export const TabButton = styled.button<{ active: boolean }>`
  padding: 10px 20px;
  border: none;
  background-color: ${({ active }) => (active ? '#008080' : '#f0f0f0')};
  color: ${({ active }) => (active ? '#ffffff' : '#333')};
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: #008080;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #006666;
  }
`;

export const AttachmentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0;
`;

export const AttachmentItem = styled.li`
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const RemoveButton = styled.button`
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;
`;
