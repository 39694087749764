import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client_client_children_assignedCustomActivities } from '../../../../../../graphql/queries/types/Client';
import { SmallSectionTitle, UserComment } from '../../../../../../style';
import WeeklyCheckInView from '../component/WeeklyCheckInView';
import { ContentSectionContainer, MobileSpace } from '../styles';
import { ActivityMainSkill } from './styles';
import { GET_CUSTOM_ACTIVITIES_FOLDER_PATH, getSignedUrl } from '../../../../../../helpers/functions';

interface Props {
  customActivity: Client_client_children_assignedCustomActivities;
  currentWeekday: number;
  tPath: string;
}

interface Attachment {
  id: string;
  attachmentType: string;
  data: string;
  signedUrl?: string;
}

const OverallTab = ({ customActivity, currentWeekday, tPath }: Props) => {
  const { t } = useTranslation();
  const { introduction, parentInteraction, customActivityAttachments, skill, name } = customActivity;
  const [attachmentsWithUrls, setAttachmentsWithUrls] = useState<Attachment[]>([]);

  useEffect(() => {
    const fetchSignedUrls = async () => {
      if (customActivityAttachments && customActivityAttachments?.length > 0) {
        const attachmentsWithSignedUrls = await Promise.all(
          customActivityAttachments.map(async (attachment: any) => {
            if (attachment.attachmentType === 'file') {
              const signedUrl = await getSignedUrl(
                GET_CUSTOM_ACTIVITIES_FOLDER_PATH(skill.id, skill.name, name),
                attachment.data,
                process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET ?? '',
              );
              return { ...attachment, signedUrl };
            }
            return attachment;
          }),
        );
        setAttachmentsWithUrls(attachmentsWithSignedUrls);
      }
    };
    fetchSignedUrls();
  }, [customActivityAttachments, skill.id, skill.name, name]);

  return (
    <div>
      <SmallSectionTitle>{t(`${tPath}.overall.weekRecord`)}</SmallSectionTitle>
      <WeeklyCheckInView customActivity={customActivity} currentWeekday={currentWeekday} />
      <div style={{ height: '30px' }}></div>
      <SmallSectionTitle>{t(`${tPath}.overall.skill`)}</SmallSectionTitle>
      <ContentSectionContainer>
        <ActivityMainSkill>{customActivity.skill.name}</ActivityMainSkill>
      </ContentSectionContainer>
      <SmallSectionTitle>{t(`${tPath}.overall.summary`)}</SmallSectionTitle>
      <ContentSectionContainer>
        <UserComment>{introduction}</UserComment>
      </ContentSectionContainer>
      <SmallSectionTitle>{t(`${tPath}.overall.parentInteraction`)}</SmallSectionTitle>
      <ContentSectionContainer>
        <UserComment>{parentInteraction}</UserComment>
      </ContentSectionContainer>
      <SmallSectionTitle>{t(`${tPath}.overall.attachments`)}</SmallSectionTitle>
      <ContentSectionContainer>
        {attachmentsWithUrls.length > 0 && (
          <div>
            {attachmentsWithUrls.map((attachment) => (
              <div key={attachment.id} style={{ marginBottom: '5px' }}>
                {attachment.attachmentType === 'file' ? (
                  <a href={attachment.signedUrl ?? '#'} target="_blank" rel="noopener noreferrer">
                    {attachment.data.split('_').slice(1).join('_')}
                  </a>
                ) : (
                  <a href={attachment.data} target="_blank" rel="noopener noreferrer">
                    {attachment.data}
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </ContentSectionContainer>
      <MobileSpace />
    </div>
  );
};

export default React.memo(OverallTab);
