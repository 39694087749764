import React, { useState, useContext, useEffect, useMemo } from 'react';
import AllActivities from './AllActivities';
import { ClientContext } from '../../ClientContext';
import ActivityPlan from './ActivityPlan';
import { GetLengthOfEnum, PenguinSmartDays, PenguinSmartTimeSlot } from '../../../../util/Date';
import { Client_client_children_assignedActivities } from '../../../../graphql/queries/types/Client';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';

interface Props {
  productType: string;
}

const ActivityTab = ({ productType }: Props) => {
  const { child, handleActivityCheckin, handleCustomActivityCheckin } = useContext(ClientContext);
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [activityId, setActivityId] = useState(localStorage.getItem('activityId') || '');
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);

  useEffect(() => {
    if (activityId !== '' || isCPC) {
      setViewAll(true);
    }
  }, [activityId]);

  const activities = child.lastAssignedActivities;
  const customActivities = child.lastAssignedCustomActivities;

  const activityPlan = child.activityPlan;

  const planActivity = useMemo(() => {
    const defaultTimeSlotLength = GetLengthOfEnum(PenguinSmartTimeSlot);
    const defaultDaysLength = GetLengthOfEnum(PenguinSmartDays);
    let activityArray: Client_client_children_assignedActivities[][][] = [];
    if (activities.length === 0) {
      return [];
    }
    for (let i = 0; i < defaultDaysLength; i++) {
      activityArray.push(new Array(defaultTimeSlotLength).fill(null).map(() => Array(0)));
    }
    if (activityPlan.length > 0) {
      activityPlan.forEach((p) => {
        const dayIndex = PenguinSmartDays[p.weekday];
        const timeIndex = PenguinSmartTimeSlot[p.timeslot];
        const index = activities.findIndex((object: Client_client_children_assignedActivities) => {
          return object.id === p.activityId;
        });
        const activity = activities[index];
        activityArray[dayIndex][timeIndex].push(activity);
      });
    }
    return activityArray;
  }, [activities, activityPlan]);

  const handleViewAll = useMemo(
    () => () => {
      setViewAll(true);
      window.scroll(0, 0);
    },
    [],
  );

  const handleViewDailyPlan = useMemo(
    () => () => {
      setViewAll(false);
      window.scroll(0, 0);
    },
    [],
  );

  return (
    <>
      {viewAll ? (
        <AllActivities
          activities={activities}
          customActivities={customActivities}
          childId={child.id}
          handleActivityCheckin={handleActivityCheckin}
          handleCustomActivityCheckin={handleCustomActivityCheckin}
          handleViewDailyPlan={handleViewDailyPlan}
        />
      ) : (
        <ActivityPlan
          planActivity={planActivity}
          latestConsultation={child.latestConsultation}
          handleActivityCheckin={handleActivityCheckin}
          handleViewAll={handleViewAll}
        />
      )}
    </>
  );
};

export default React.memo(ActivityTab);
