import React, { useState } from 'react';
import { RANDOM_COUNT } from '../../ActivityComponent';
import { useTranslation } from 'react-i18next';

const ViewMoreActivities = ({ totalAvailableActivities, viewMoreActivities }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleView = () => {
    setIsExpanded(!isExpanded);
    viewMoreActivities();
  };

  return (
    <div style={{ margin: '0 10px', paddingTop: '5px', display: 'flex', alignItems: 'center' }}>
      {totalAvailableActivities > RANDOM_COUNT && (
        <div
          onClick={handleToggleView}
          style={{
            color: 'teal',
            fontWeight: 600,
            cursor: 'pointer',
            textDecoration: 'underline',
            fontSize: '14px', // Reduced font size to 14px
          }}
        >
          {isExpanded
            ? t('therapist.consultationScreen.activities.viewLess')
            : t('therapist.consultationScreen.activities.viewAll')}
        </div>
      )}
    </div>
  );
};

export default ViewMoreActivities;
