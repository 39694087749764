import { Row, Col } from 'antd';
import React from 'react';
import { FiEdit2 } from 'react-icons/fi';
import ProgressBarComponent from '../../ProgressBarComponent';

export const ActivityRow = ({ activity, isCustom, onClick, progressValue, maxValue, removeComponent }) => {
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col span={21}>
          <Row onClick={onClick}>
            <Col span={24}>
              <Row align="middle">
                <Col>{activity.name}</Col>
                <FiEdit2 style={{ margin: '0 6px' }} />
              </Row>
            </Col>

            <Col span={24} style={{ margin: '8px 0 0 0' }}>
              <ProgressBarComponent value={progressValue} maxValue={maxValue} overall={false} />
            </Col>
          </Row>
        </Col>

        {removeComponent && (
          <Col span={2}>
            <Row justify="end">{removeComponent}</Row>
          </Col>
        )}
      </Row>
      <div style={{ height: '20px' }}></div>
    </div>
  );
};
