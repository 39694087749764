import { Col } from 'antd';
import React from 'react';
import { PopUpDiv, CloseXButton } from '../../StyledComponents';

export const ActivityDetailsPopup = ({ isOpen, closeDetails, isCPC, removeComponent, viewComponent }) => {
  if (!isOpen) return null;

  return (
    <PopUpDiv>
      <Col>
        <div onClick={closeDetails} style={{ position: 'absolute', right: '0px' }}>
          <CloseXButton />
        </div>

        {isCPC && removeComponent}

        {viewComponent}
      </Col>
    </PopUpDiv>
  );
};
