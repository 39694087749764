import React, { useState, useContext } from 'react';
import UserContext from '../../common/UserContext';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import LocaleDropdown from '../../common/Account/components/LocaleDropdown';
import PhoneComponent from '../../common/Account/components/PhoneComponent';
import UserInfoComponent from '../../common/Account/components/UserInfoComponent';
import UserNameInfoComponent from '../../common/Account/components/UserNameInfoComponent';
import ReturnMessageComponent from '../../common/ReturnMessageComponent';
import { ReturnMessage } from '../../../types';
import ClientDashboardNotificationCard from './Notification';
import { ClientContext } from '../ClientContext';
import { useHistory } from 'react-router';
import { RouteSectionContainer } from '../styles';
import { ActionTitle, GeneralSegment, SectionTitle } from '../../../style';
import { OptionSegment } from './styles';
import ActiveChildDropdown from '../../common/Account/components/ActiveChildDropdown';
import SmsCheckbox from '../../common/Account/components/SmsCheckbox';
import LogoutComponentProfilePage from '../../common/LogoutComponentProfilePage';
import CountryDropdown from '../../common/Account/components/CountryDropdown';
import TimeZoneDropdown from '../../common/Account/components/TimeZoneDropdown';
import api_address from '../../../constants/config';
import { CPC_PRODUCT_TYPES, PEA_PRODUCT_TYPES } from '../../../util/Product';

interface Props {}

const ProfileRoute = ({}: Props) => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { client, setChild } = useContext(ClientContext);
  const { id, name, email, phoneNumber, locale, activeChildId, smsNotification, uuid, country, firstName, lastName } =
    user;
  const [returnMessage, setReturnMessage] = useState<ReturnMessage | undefined>(undefined);
  const child = client.children.filter((child) => child.id == client.user.activeChildId)[0];
  const productType = child.product.productTypesName;
  const isCPC = CPC_PRODUCT_TYPES.includes(productType);
  const isPEA = PEA_PRODUCT_TYPES.includes(productType);

  return (
    <RouteSectionContainer>
      {returnMessage && <ReturnMessageComponent returnMessage={returnMessage} />}
      <GeneralSegment>
        <SectionTitle>{t('auth.title')}</SectionTitle>
        <hr />
        <UserNameInfoComponent
          userId={id}
          title={t('auth.name')}
          firstName={firstName}
          lastName={lastName}
          setReturnMessage={setReturnMessage}
          userUuid={uuid}
        />
        <UserInfoComponent
          userId={id}
          title={t('auth.email')}
          attribute="Email"
          value={email}
          setReturnMessage={setReturnMessage}
          userUuid={uuid}
        />
        <PhoneComponent
          userId={id}
          title={t('auth.phoneNumber')}
          attribute="Phone Number"
          value={phoneNumber ? phoneNumber : ''}
          setReturnMessage={setReturnMessage}
          userUuid={uuid}
        />
        <LocaleDropdown userId={id} value={locale} setReturnMessage={setReturnMessage} />
        <ActiveChildDropdown
          userId={id}
          value={activeChildId}
          children={client.children}
          language={locale}
          setChild={setChild}
          setReturnMessage={setReturnMessage}
        />
        <CountryDropdown userId={id} value={country} setReturnMessage={setReturnMessage} userUuid={uuid} />
        <SmsCheckbox userId={id} value={smsNotification} setReturnMessage={setReturnMessage} userUuid={uuid} />
        <TimeZoneDropdown
          userId={client.id}
          value={client.timeZone}
          setReturnMessage={setReturnMessage}
          userUuid={uuid}
        />
      </GeneralSegment>
      <OptionSegment onClick={() => history.push('/program')}>
        <ActionTitle>{t('navBar.program')}</ActionTitle>
        <Icon name="chevron right" link style={{ alignSelf: 'center' }} />
      </OptionSegment>
      <OptionSegment onClick={() => window.open(api_address + 'evaluation-history', '_blank')}>
        <ActionTitle>{t('navBar.evaluationHistory')}</ActionTitle>
        <Icon name="chevron right" link style={{ alignSelf: 'center' }} />
      </OptionSegment>
      <OptionSegment
        onClick={() => {
          if (isCPC) {
            history.push('/consentFormCPC');
          } else if (isPEA) {
            history.push('/consentFormPEA');
          }
        }}
      >
        <ActionTitle>{t('navBar.consentForm')}</ActionTitle>
        <Icon name="chevron right" link style={{ alignSelf: 'center' }} />
      </OptionSegment>
      {user && user.id && <LogoutComponentProfilePage />}
    </RouteSectionContainer>
  );
};

export default ProfileRoute;
