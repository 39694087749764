import React from 'react';
import { useHistory } from 'react-router';
import {
  GuidelineTextSection,
  GuidelineText,
  GuidelineBoldText,
  GuidelineImportantText,
  GuideLineModifyingText,
} from '../styles';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { RouteSectionContainer } from '../../../styles';

const Guidelines = () => {
  window.scroll(0, 0);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <RouteSectionContainer>
      <Icon className="chevron left" size="large" link circular onClick={() => history.push('/profile')} />
      <p style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>{t('utils.guidelines.title')}</p>
      <GuidelineTextSection>
        {/* Effective Session Suggestions */}
        <GuidelineText>
          <GuidelineBoldText>{t('utils.sessionGuidelines.effectiveSession.suggestions')}</GuidelineBoldText>
          <div>
            <span>1.1. </span>
            {t('utils.sessionGuidelines.effectiveSession.beforeSession')}
          </div>
          <div>
            <span>1.2. </span>
            {t('utils.sessionGuidelines.effectiveSession.environment')}
          </div>
          <div>
            <span>1.3. </span>
            {t('utils.sessionGuidelines.effectiveSession.headphones')}
          </div>
          <div>
            <span>1.4. </span>
            {t('utils.sessionGuidelines.effectiveSession.clothing')}
          </div>
        </GuidelineText>

        {/* Session Times and Confirmation */}
        <GuidelineText>
          <GuidelineBoldText>{t('utils.sessionGuidelines.sessionTimes.sessionConfirmation')} </GuidelineBoldText>
          <br />
          {t('utils.sessionGuidelines.sessionTimes.scheduling')}
        </GuidelineText>

        {/* Session Reminders */}
        <GuidelineText>
          <GuidelineBoldText>{t('utils.sessionGuidelines.sessionReminders.reminders')} </GuidelineBoldText>
          {t('utils.sessionGuidelines.sessionReminders.confirmationAndReminder')}
          <GuidelineImportantText>
            {t('utils.sessionGuidelines.sessionReminders.calendarInvitations')}
          </GuidelineImportantText>
        </GuidelineText>

        {/* Late & No-Show Policy */}
        <GuidelineText>
          <GuidelineBoldText>{t('utils.sessionGuidelines.lateNoShowPolicy.latePolicy')}</GuidelineBoldText>
          <br />
          {t('utils.sessionGuidelines.lateNoShowPolicy.timeliness')}
          <br />
          <span>4.1. </span>
          {t('utils.sessionGuidelines.lateNoShowPolicy.coachContact')}
          <br />
          <span>4.2. </span>
          {t('utils.sessionGuidelines.lateNoShowPolicy.noShowForfeiture')}
        </GuidelineText>

        {/* Rescheduling and Cancellations */}
        <GuidelineText>
          <GuidelineBoldText>
            {t('utils.sessionGuidelines.reschedulingAndCancellations.reschedulingPolicy')}
          </GuidelineBoldText>
          <div>
            <span>5.1. </span>

            {t('utils.sessionGuidelines.reschedulingAndCancellations.advanceRescheduling')}
          </div>
          <div>
            <span>5.2. </span>

            {t('utils.sessionGuidelines.reschedulingAndCancellations.lateRescheduling')}
          </div>
          <div>
            <span>5.3. </span>
            {t('utils.sessionGuidelines.reschedulingAndCancellations.maximumChanges')}
          </div>
        </GuidelineText>

        {/* Between-Session Contact */}
        <GuidelineText>
          <GuidelineBoldText>{t('utils.sessionGuidelines.betweenSessionContact.contactGuidelines')}</GuidelineBoldText>
          <br />
          {t('utils.sessionGuidelines.betweenSessionContact.messageBoard')}
          <br />
          <GuidelineImportantText>
            <span>6.1. </span>
            {t('utils.sessionGuidelines.betweenSessionContact.privacyPolicy')}
          </GuidelineImportantText>
          <br />
          <span>6.2. </span>
          {t('utils.sessionGuidelines.betweenSessionContact.videoReviewLimit')}
        </GuidelineText>

        {/* Session Video Usage */}
        <GuidelineText>
          <GuidelineBoldText>{t('utils.sessionGuidelines.sessionVideoUsage.videoPolicy')} </GuidelineBoldText>
          {t('utils.sessionGuidelines.sessionVideoUsage.recording')}
        </GuidelineText>

        {/* Reachability & Active Membership */}
        <GuidelineText>
          <GuidelineBoldText>
            {t('utils.sessionGuidelines.reachabilityAndMembership.reachabilityPolicy')}
          </GuidelineBoldText>
          <br />
          {t('utils.sessionGuidelines.reachabilityAndMembership.communicationCheck')}
          <br />
          <span>8.1. </span>
          {t('utils.sessionGuidelines.reachabilityAndMembership.unreachablePause')}
          <br />
          <span>8.2. </span>
          {t('utils.sessionGuidelines.reachabilityAndMembership.pausePolicy')}
          <br />
          <span>8.3. </span>
          {t('utils.sessionGuidelines.reachabilityAndMembership.resumingMembership')}
        </GuidelineText>

        {/* Last Updated Text */}
        <div style={{ textAlign: 'center', marginTop: '20px', fontStyle: 'italic' }}>
          {t('utils.guidelines.lastUpdated')}
        </div>
      </GuidelineTextSection>
    </RouteSectionContainer>
  );
};

export default Guidelines;
