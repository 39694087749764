import { Row } from 'antd';
import React from 'react';
import { BsTrash3 } from 'react-icons/bs';
import ActivityRemoveButton from '../../../Tasks/ActivityRemoveButton';
import { RoundBorderButton } from '../../StyledComponents';
import { useTranslation } from 'react-i18next';

export const RemoveActivity = ({ isLongButton }) => {
  const { t } = useTranslation();

  const h = 28;
  const removeActivityIcon = <BsTrash3 />;
  return (
    <div>
      {isLongButton ? (
        <RoundBorderButton style={{ backgroundColor: 'teal', margin: '0px 10px', padding: '0px 15px' }}>
          <div style={{ padding: '0 3px 0 0' }}>{t('therapist.consultationScreen.removeActivity')}</div>
          {removeActivityIcon}
        </RoundBorderButton>
      ) : (
        <div
          style={{
            height: `${h}px`,
            width: `${h}px`,
            borderRadius: '50%',
            backgroundColor: 'teal',
            color: 'white',
            fontSize: '13px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {removeActivityIcon}
        </div>
      )}
    </div>
  );
};

export const RemoveActivityComponent = ({ activityToRemove, isLongButton, closeActivityDetails, isCustom }) => {
  if (!activityToRemove) return null;
  const activityId = isCustom ? activityToRemove.customActivity.id : activityToRemove.activity.id;
  const consultationId = activityToRemove.consultation.id;

  return (
    <Row>
      <ActivityRemoveButton
        activityId={activityId}
        consultationId={consultationId}
        trigger={<RemoveActivity isLongButton={isLongButton} />}
        onClickFinish={closeActivityDetails}
        isCustom={isCustom}
      />
    </Row>
  );
};
