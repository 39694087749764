import gql from 'graphql-tag';
import { ReturnMessage } from '../../types';
import { CustomActivity } from '../queries/types/CustomActivities';

export const ADD_CUSTOM_ACTIVITIES_TO_CONSULTATION = gql`
  mutation AddCustomActivitiesToConsultation($customActivityIds: [ID!]!, $consultationId: ID!) {
    addCustomActivitiesToConsultation(customActivityIds: $customActivityIds, consultationId: $consultationId) {
      customActivities {
        id
        name
        introduction
        parentInteraction
      }
      returnMessage {
        status
        message
      }
    }
  }
`;

export interface Data {
  addCustomActivitiesToConsultation: { customActivities: CustomActivity[]; returnMessage: ReturnMessage };
}

export interface Variables {
  customActivityIds: number[];
  consultationId: number;
}
