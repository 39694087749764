import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItemProps, Dropdown } from 'semantic-ui-react';
import { REMOVE_ACTIVITIES_TO_CHILD_MUTATION } from '../../../../graphql/mutations/removeActivitiesToChild';
import { REMOVE_CUSTOM_ACTIVITIES_TO_CHILD_MUTATION } from '../../../../graphql/mutations/removeCustomActivitiesToChild';
import { useMutation } from 'react-apollo';
import { ADD_ACTIVITY_REJECT_MUTATION } from '../../../../graphql/mutations/addActivityReject';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import {
  RemoveActivitiesToChild,
  RemoveActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/RemoveActivitiesToChild';
import {
  RemoveCustomActivitiesToChildData,
  RemoveCustomActivitiesToChildVariables,
} from '../../../../graphql/mutations/removeCustomActivitiesToChild';
import { AddActivityReject, AddActivityRejectVariables } from '../../../../graphql/mutations/types/AddActivityReject';
import { FAST_POLL } from '../constants';
import {
  ADD_CUSTOM_ACTIVITY_REJECT_MUTATION,
  AddCustomActivityRejectData,
  AddCustomActivityRejectVariables,
} from '../../../../graphql/mutations/addCustomActivityReject';

interface Props {
  activityId: string;
  consultationId: string;
  trigger: JSX.Element;
  onClickFinish: () => void;
  isCustom?: boolean;
}

const ActivityRemoveButton = ({ activityId, consultationId, trigger, onClickFinish, isCustom = false }: Props) => {
  const { t } = useTranslation();

  const {
    setReturnMessage,
    consultation: { id, child },
    setPollingCount,
  } = useContext(TherapistConsultationScreenContext);

  const removeActivityMutation = isCustom
    ? REMOVE_CUSTOM_ACTIVITIES_TO_CHILD_MUTATION
    : REMOVE_ACTIVITIES_TO_CHILD_MUTATION;
  const addActivityRejectMutation = isCustom ? ADD_CUSTOM_ACTIVITY_REJECT_MUTATION : ADD_ACTIVITY_REJECT_MUTATION;

  const [removeActivitiesToChild, { data }] = useMutation<
    RemoveActivitiesToChild | RemoveCustomActivitiesToChildData,
    RemoveActivitiesToChildVariables | RemoveCustomActivitiesToChildVariables
  >(removeActivityMutation, {
    refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: id } }],
    onCompleted: () => {
      setTimeout(() => {
        setPollingCount((prevCount) => prevCount - 1);
      }, FAST_POLL);
    },
  });

  async function handleRemoveActivity() {
    await removeActivitiesToChild({
      variables: isCustom
        ? { customActivityIds: [activityId], childId: child.id, consultationIds: [consultationId] }
        : { activityIds: [activityId], childId: child.id, consultationIds: [consultationId] },
    });
  }

  const [addActivityReject] = useMutation<
    AddActivityReject | AddCustomActivityRejectData,
    AddActivityRejectVariables | AddCustomActivityRejectVariables
  >(addActivityRejectMutation);

  async function handleAddActivityReject(data: DropdownItemProps) {
    await addActivityReject({
      variables: isCustom
        ? { customActivityId: activityId, childId: child.id, reason: data.text as string }
        : { activityId: activityId, childId: child.id, reason: data.text as string },
    });
  }

  const handleClick = async (data: DropdownItemProps) => {
    setPollingCount((prevCount) => prevCount + 1);
    await handleRemoveActivity();
    await handleAddActivityReject(data);
    onClickFinish();
  };

  useEffect(() => {
    if (data && data.removeActivitiesToChild) {
      setReturnMessage(data.removeActivitiesToChild.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]);

  return (
    <Dropdown style={{ height: 'max-content' }} direction="left" icon={null} trigger={trigger}>
      <Dropdown.Menu style={{ textAlign: 'center' }}>
        <Dropdown.Header content={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReason')} />
        <Dropdown.Divider />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.alreadyFinished')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.tooSimple')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.tooDifficult')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.doesNotMatchWithGoal')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.notSuitable')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
        <Dropdown.Item
          text={t('therapist.consultationScreen.task.taskAndHw.taskCard.switchReasons.otherReason')}
          onClick={(e, data: DropdownItemProps) => handleClick(data)}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActivityRemoveButton;
