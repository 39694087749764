export function getRandomElements<T>(array: T[], numberOfElements: number): T[] {
  const randomIndices: number[] = [];
  const result: T[] = [];

  if (numberOfElements >= array.length) {
    return array.slice(); // Return a shallow copy of the original array
  }

  while (randomIndices.length < numberOfElements) {
    const randomIndex = Math.floor(Math.random() * array.length);

    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
      result.push(array[randomIndex]);
    }
  }

  return result;
}

export const filterActivities = (activities, skillId, isCustom = false) => {
  return activities.filter((a) =>
    isCustom ? a.customActivity.skill.id === skillId : a.activity.mainSkill.id === skillId,
  );
};
