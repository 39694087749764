import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { FaUsers } from 'react-icons/fa';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { GET_CUSTOM_ACTIVITIES_FOLDER_PATH, getSignedUrl } from '../../../../helpers/functions';
import { ActivityCardDiv, BigTitle, Title, Text, TextHiddenEllipsis, SuggestChangesText } from './StyledComponents';

const MARGIN_HEIGHT = 10;
const TEXT_PADDING_WIDTH_LARGE = 35;

interface Props {
  customActivity: any; // Replace `any` with your actual type
  isSelected: boolean;
}

interface Attachment {
  id: string;
  attachmentType: string;
  data: string;
  signedUrl?: string;
}

const CustomActivityCard = ({ customActivity, isSelected }: Props) => {
  const { id, name, introduction, parentInteraction, customActivityAttachments, skill } = customActivity;
  const { t } = useTranslation();

  const WINDOW_WIDTH = window.innerWidth;
  const CARD_WIDTH = isSelected ? WINDOW_WIDTH * 0.6 : WINDOW_WIDTH * 0.6 * 0.97; // selected activity will appear larger than non-selected activities
  const CARD_BORDER = isSelected ? '2px outset teal' : 'none';

  const [attachmentsWithUrls, setAttachmentsWithUrls] = useState<Attachment[]>([]);

  useEffect(() => {
    const fetchSignedUrls = async () => {
      if (customActivityAttachments?.length > 0) {
        const attachmentsWithSignedUrls = await Promise.all(
          customActivity.customActivityAttachments.map(async (attachment: any) => {
            if (attachment.attachmentType === 'file') {
              const signedUrl = await getSignedUrl(
                GET_CUSTOM_ACTIVITIES_FOLDER_PATH(skill.id, skill.name, name),
                attachment.data,
                process.env.REACT_APP_AWS_UPLOADER_S3_BUCKET ?? '',
              );
              return { ...attachment, signedUrl };
            }
            return attachment;
          }),
        );
        setAttachmentsWithUrls(attachmentsWithSignedUrls);
      }
    };
    fetchSignedUrls();
  }, [customActivity]);

  if (!customActivity) {
    return null; // Handle the case where customActivity is null
  }

  return (
    <ActivityCardDiv
      style={{ width: `${CARD_WIDTH}px`, border: CARD_BORDER, cursor: isSelected ? 'default' : 'pointer' }}
    >
      <BigTitle>
        {`${name} (ID: ${id})`} <FaUsers />
      </BigTitle>

      {isSelected ? (
        <>
          <div>
            <Title style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
              {t('therapist.consultationScreen.activities.suggestMoreActivities.summary')}
            </Title>
            <Text>{introduction}</Text>

            <Title style={{ margin: `${MARGIN_HEIGHT}px 0 0 0` }}>
              {t('therapist.consultationScreen.activities.suggestMoreActivities.interactionTactic')}
            </Title>
            <Text>{parentInteraction}</Text>
          </div>

          {attachmentsWithUrls.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <Title>{t('therapist.consultationScreen.activities.customActivityCard.attachments')}</Title>
              {attachmentsWithUrls.map((attachment) => (
                <div key={attachment.id} style={{ marginBottom: '5px' }}>
                  {attachment.attachmentType === 'file' ? (
                    <a href={attachment.signedUrl ?? 'test'} target="_blank" rel="noopener noreferrer">
                      {attachment.data.split('_').slice(1).join('_')}
                    </a>
                  ) : (
                    <a href={attachment.data} target="_blank" rel="noopener noreferrer">
                      {attachment.data}
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}
          <SuggestChangesText>
            {t('therapist.consultationScreen.activities.customActivityCard.suggestChangesText')}
          </SuggestChangesText>
        </>
      ) : (
        <Col>
          <Row>
            <Col span={13}>
              <Title>{t('therapist.consultationScreen.activities.suggestMoreActivities.summary')}</Title>
              <TextHiddenEllipsis style={{ padding: `0 ${TEXT_PADDING_WIDTH_LARGE}px 0 0` }}>
                {introduction}
              </TextHiddenEllipsis>
            </Col>
            <Col span={10}>
              <Title>{t('therapist.consultationScreen.activities.suggestMoreActivities.interactionTactic')}</Title>
              <TextHiddenEllipsis>{parentInteraction}</TextHiddenEllipsis>
            </Col>
          </Row>
          <Row justify="center" style={{ margin: `0px 0 -12px 0`, fontSize: '30px', color: 'lightgrey' }}>
            <RiArrowDropDownLine />
          </Row>
        </Col>
      )}
    </ActivityCardDiv>
  );
};

export default CustomActivityCard;
