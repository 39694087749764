import gql from 'graphql-tag';

export const CUSTOM_ACTIVITY_CHECKIN_MUTATION = gql`
  mutation AddCheckinToCustomActivity($childId: ID!, $customActivityId: ID!) {
    addCheckinToCustomActivity(childId: $childId, customActivityId: $customActivityId) {
      customActivityCheckin {
        customActivity {
          id
          name
          skill {
            id
            name
          }
        }
      }
      returnMessage {
        status
        message
      }
    }
  }
`;
