import gql from 'graphql-tag';
import { Message } from '../../types';
import { CustomActivity } from './types/CustomActivities';

export const GET_CUSTOM_ACTIVITIES_BY_SKILL_ID = gql`
  query GetCustomActivitiesBySkillId($skillId: ID!) {
    getCustomActivitiesBySkillId(skillId: $skillId) {
      id
      name
      skill {
        id
        name
      }
      customActivityAttachments {
        id
        attachmentType
        data
      }
      introduction
      parentInteraction
      createdAt
    }
  }
`;

export interface GetCustomActivitiesBySkillIdData {
  getCustomActivitiesBySkillId: GetCustomActivitiesBySkillIdData_customActivities[];
}

export interface GetCustomActivitiesBySkillIdVariables {
  skillId: string;
}

export interface GetCustomActivitiesBySkillIdData_customActivities {
  id: string;
  name: string;
  skill: GetCustomActivitiesBySkillIdData_customActivities_skill;
  customActivityAttachments: GetCustomActivitiesBySkillIdData_customActivities_customActivityAttachments;
  introduction?: string;
  parentInteraction?: string;
}

export interface GetCustomActivitiesBySkillIdData_customActivities_skill {
  id: string;
  name: string;
}

export interface GetCustomActivitiesBySkillIdData_customActivities_customActivityAttachments {
  id: string;
  attachmentType: string;
  data: string;
}
