import styled from 'styled-components';

export const Tooltip = styled.div`
  position: absolute;
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 4px;
  z-index: 1;
  top: 25px;
  left: 22px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 13px;
`;
