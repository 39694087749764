import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityImage, ActivityHeaderContainer, ActivityNameContainer, Selective, TitleMainSkill } from './styles';
import {
  ContentContainer,
  GoalModalContainer,
  GoalModalContainerWithClose,
  GoalModalContent,
  GoalModalHeader,
  GoalModalMask,
  GoalModalTabContainer,
  GoalTitle,
} from '../../../DashboardRoute/styles';
import useLockBodyScroll from '../../../../../util/Hooks/useLockBodyScroll';
import { TabMenuContainer } from '../../../../common/TabMenu/styles';
import OverallTab from './OverallTab';
import ModalInsideCloseButton from '../../../../common/ModalInsideCloseButton';
import Tab from './component/Tab';
import CheckinButton from './component/CheckinButton';
import { Client_client_children_assignedCustomActivities } from '../../../../../graphql/queries/types/Client';
import therapistIcon from '../../../../../../src/components/Client/LibraryRoute/Blog/assets/therapist.png';

interface HeaderComponentProps {
  customActivity: Client_client_children_assignedCustomActivities;
  tPath: string;
}
const HeaderComponent = React.memo(({ customActivity, tPath }: HeaderComponentProps) => {
  const { t } = useTranslation();
  return (
    <ActivityHeaderContainer>
      <ActivityImage src={therapistIcon} />
      <ActivityNameContainer>
        <TitleMainSkill>{customActivity.skill.name}</TitleMainSkill>
        <GoalTitle>{customActivity.name}</GoalTitle>
        <Selective>{t(`${tPath}.selective`)}</Selective>
      </ActivityNameContainer>
    </ActivityHeaderContainer>
  );
});

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customActivity: Client_client_children_assignedCustomActivities;
  handleCustomActivityCheckin: (customActivityIDs: Array<string>) => void;
  currentWeekday: number;
}

const CustomActivityModal = ({ open, setOpen, customActivity, handleCustomActivityCheckin, currentWeekday }: Props) => {
  useLockBodyScroll(open);
  const { t } = useTranslation();
  const [selection, setSelection] = useState(0);
  const tPath = useMemo(() => {
    return 'client.task.activities';
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelection(0);
    localStorage.removeItem('activityId');
  }, [setOpen]);

  const tabTitles = [t(`${tPath}.overall.title`)];

  const tabContents = [<OverallTab customActivity={customActivity} tPath={tPath} currentWeekday={currentWeekday} />];

  return (
    <GoalModalMask open={open}>
      <GoalModalContainerWithClose>
        <GoalModalContainer>
          <GoalModalHeader>
            <ModalInsideCloseButton handleClose={handleClose} />
            <HeaderComponent customActivity={customActivity} tPath={tPath} />
          </GoalModalHeader>
          <GoalModalTabContainer>
            <TabMenuContainer>
              {tabTitles.map((title, index) => {
                const isSelect = index === selection;
                return <Tab isSelect={isSelect} index={index} title={title} handleTabSelect={setSelection} />;
              })}
            </TabMenuContainer>
          </GoalModalTabContainer>
          <GoalModalContent>
            <ContentContainer>{tabContents[selection]}</ContentContainer>
            <CheckinButton
              customActivityId={customActivity.id}
              handleCustomActivityCheckin={handleCustomActivityCheckin}
            />
          </GoalModalContent>
        </GoalModalContainer>
      </GoalModalContainerWithClose>
    </GoalModalMask>
  );
};

export default React.memo(CustomActivityModal);
