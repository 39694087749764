import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Space } from 'antd';
import '../styles.css';
import {
  ModalMask,
  ModalContainerWithClose,
  ModalContainer,
  ModalHeader,
  HeaderTitle,
  ModalContent,
  ContentText,
  ModalButtonContainer,
  OptionButton,
  ShowErrNextButton,
  NextButton,
  ActionTitle,
} from '../../../common/styles';
import DoubleQuotesLeftIcon from './assets/doubleQuotesLeftIcon';
import DoubleQuotesRightIcon from './assets/doubleQuotesRightIcon';
const ConfirmMsgModal = (props) => {
  const subOptions = props.option.subOptions ? props.option.subOptions : undefined;
  const [textMsg, setTextMsg] = useState('');
  const [subOption, setSubOption] = useState({});
  const { t } = useTranslation();
  const setClicked = (index, subOption) => {
    setTextMsg(subOption.msg);
    setSubOption(subOption);
  };
  useEffect(() => {
    props.disableScroll();

    if (subOptions !== undefined) {
      setTextMsg(subOptions[0].value.msg);
      setSubOption(subOptions[0].value);
    } else {
      let textMsg = '';
      if (props.option.key === 3) textMsg = props.option.msg + props.backupLink;
      else textMsg = props.option.msg;
      setTextMsg(textMsg);
    }
  }, []);
  const onClick = (e: any) => {
    setClicked(e.target.value.index, e.target.value);
  };
  return (
    <div>
      <ModalMask open={true}>
        <ModalContainerWithClose>
          <ModalContainer>
            <ModalHeader style={{ marginBottom: '8px' }}>
              <HeaderTitle
                style={{
                  fontSize: '24px',
                  color: 'black',
                  textAlign: 'center',
                  paddingBottom: subOptions !== undefined ? '0px' : '8px',
                }}
              >
                {props.modalHeader}
                {subOptions !== undefined ? props.modalHeader1 : props.modalHeader2}
              </HeaderTitle>
            </ModalHeader>
            <ModalButtonContainer
              style={{
                flexDirection: 'row',
                marginTop: subOptions !== undefined ? '8px' : '0px',
                marginBottom: subOptions !== undefined ? '2rem' : '0px',
              }}
            >
              {subOptions !== undefined && (
                <Radio.Group
                  size="large"
                  style={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}
                  options={subOptions}
                  value={subOption}
                  onChange={onClick}
                ></Radio.Group>
              )}
            </ModalButtonContainer>
            <ModalContent style={{ position: 'relative' }}>
              <DoubleQuotesLeftIcon />
              <ContentText
                style={{
                  textAlign: 'center',
                  margin: '0 32px',
                  wordWrap: 'break-word',
                }}
              >
                {textMsg}
              </ContentText>
              <DoubleQuotesRightIcon />
            </ModalContent>
            {props.disabled && (
              <ModalContent>
                <ContentText
                  style={{
                    paddingTop: '12px',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    color: 'gray',
                  }}
                >
                  {t('therapist.dashboard.myTasks.modal.description')}
                </ContentText>
              </ModalContent>
            )}
            <ModalButtonContainer>
              <NextButton
                onClick={function (event) {
                  props.enableScroll();
                  props.onAction(Object.keys(subOption).length === 0 ? props.option : subOption);
                  props.onCancel();
                }}
                style={{
                  marginTop: '8px',
                  background: props.disabled ? 'gray' : 'eal',
                  pointerEvents: props.disabled ? 'none' : 'auto',
                }}
              >
                <ActionTitle>{props.actionText}</ActionTitle>
              </NextButton>
            </ModalButtonContainer>
            <p
              onClick={function (event) {
                props.enableScroll();
                props.onCancel();
              }}
              style={{ marginTop: '8px', color: 'gray', cursor: 'pointer' }}
            >
              {t('button.notnow')}
            </p>
          </ModalContainer>
        </ModalContainerWithClose>
      </ModalMask>
    </div>
  );
};
export default ConfirmMsgModal;
