import React from 'react';
import { useTranslation } from 'react-i18next';

const NumOfAvailableActivities = ({ totalAvailableActivities }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        color: 'teal',
        fontWeight: 600,
        padding: '0 10px',
        paddingTop: '5px',
        paddingLeft: '20px',
        fontSize: '14px', // Reduced font size to 14px
        cursor: 'default',
        whiteSpace: 'nowrap', // Prevents wrapping of text
      }}
    >
      {`${t('therapist.consultationScreen.activities.numberOfActivities')} ${totalAvailableActivities}`}
    </div>
  );
};

export default NumOfAvailableActivities;
