import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import TherapistConsultationScreenContext from '../TherapistConsultationScreenContext';
import {
  Consultation_consultation_child_activityChildren,
  Consultation_consultation_child_customActivityChildren,
  Consultation_consultation_child_customActivityChildren_customActivity,
  Consultation_consultation_child_goals,
} from '../../../../graphql/queries/types/Consultation';
import { Consultation_consultation_child_goals_preskills } from '../../../../graphql/queries/types/Consultation';
import { Col, Row } from 'antd';
import { RoundBorderButton, PopUpDiv, CloseXButton } from './StyledComponents';
import { PRESKILL_ACTIVITIES_QUERY } from '../../../../graphql/queries/getActivitiesFromPreskills';
import {
  GetActivitiesFromPreskills,
  GetActivitiesFromPreskillsVariables,
  GetActivitiesFromPreskills_getActivitiesFromPreskills,
} from '../../../../graphql/queries/types/GetActivitiesFromPreskills';
import ActivityCard from './ActivityCard';
import { BsPlusLg } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import {
  AddActivitiesToChild,
  AddActivitiesToChildVariables,
} from '../../../../graphql/mutations/types/AddActivitiesToChild';
import { ADD_ACTIVITIES_TO_CHILD } from '../../../../graphql/mutations/addActivitiesToChild';
import { CONSULTATION_QUERY } from '../../../../graphql/queries/consultation';
import { useMutation } from 'react-apollo';
import { FAST_POLL } from '../constants';
import { CPC_PRODUCT_TYPES } from '../../../../util/Product';
import SuggestMoreActivities from './components/SuggestMoreActivities/SuggestMoreActivities';
import {
  ADD_CUSTOM_ACTIVITIES_TO_CHILD,
  AddCustomActivitiesToChild,
  AddCustomActivitiesToChildVariables,
} from '../../../../graphql/mutations/addCustomActivitiesToChild';
import CustomActivityCard from './CustomActivityCard';
import NumOfAvailableActivities from './components/AvailableActivities/AvailableActivities';
import ViewMoreActivities from './components/ViewMoreActivities/ViewMoreActivities';
import RefreshButton from './components/RefreshButton/RefreshButton';
import { filterActivities, getRandomElements } from './components/helper';
import { RandomActivityComponent, RandomActivityConfirmOrSuggest } from './components/RandomActivity/RandomActivity';
import { RemoveActivityComponent } from './components/RemoveActivity/RemoveActivity';
import { ActivityRow } from './components/ActivityRow/ActivityRow';
import { ActivityDetailsPopup } from './components/ActivityDetailsPopUp/ActivityDetailsPopUp';
import { TotalCheckInsActivity } from './components/TotalCheckins/TotalCheckins';
import {
  GetCustomActivitiesBySkillIdData,
  GetCustomActivitiesBySkillIdVariables,
  GET_CUSTOM_ACTIVITIES_BY_SKILL_ID,
  GetCustomActivitiesBySkillIdData_customActivities,
} from '../../../../graphql/queries/getCustomActivitiesBySkillId';

const BUTTON_HEIGHT = 25;
const MAX_ACTIVITY_CHECK_INS = 20;
export const RANDOM_COUNT = 2;
export const DEFAULT_INDEX = -1;
interface Props {
  skill: Consultation_consultation_child_goals_preskills;
  isDropDown: boolean;
  handleDropDownClick: () => void;
  goal: Consultation_consultation_child_goals;
}

const ActivityComponent = ({ skill, isDropDown, handleDropDownClick, goal }: Props) => {
  const { t } = useTranslation();

  const {
    consultation,
    consultation: {
      child: {
        product: { productTypesName },
      },
    },
    pollingCount,
    setPollingCount,
  } = useContext(TherapistConsultationScreenContext);

  const isCPC = CPC_PRODUCT_TYPES.includes(productTypesName);
  const curGoal = consultation.child.goals.find((g) => {
    return g.id === goal.id;
  });

  const curSkill = curGoal?.goalPreskills.find((pS) => pS.skillId === skill.id);

  const { data: { getCustomActivitiesBySkillId = [] } = {} } = useQuery<
    GetCustomActivitiesBySkillIdData,
    GetCustomActivitiesBySkillIdVariables
  >(GET_CUSTOM_ACTIVITIES_BY_SKILL_ID, {
    variables: { skillId: skill.id },
  });

  const [allCustomActivities, setAllCustomActivities] = useState<GetCustomActivitiesBySkillIdData_customActivities[]>(
    [],
  );
  useEffect(() => {
    if (getCustomActivitiesBySkillId && getCustomActivitiesBySkillId.length > 0) {
      setAllCustomActivities(getCustomActivitiesBySkillId);
    }
  }, [getCustomActivitiesBySkillId]);

  const [allAvailableCustomActivities, setAllAvailableCustomActivities] = useState<
    GetCustomActivitiesBySkillIdData_customActivities[]
  >([]);

  const [assignedCustomActivities, setAssignedCustomActivities] = useState<
    Consultation_consultation_child_customActivityChildren[]
  >([]);

  useEffect(() => {
    setAllAvailableCustomActivities(
      allCustomActivities.filter((a) => !assignedCustomActivities.some((b) => b.customActivity.id === a.id)),
    );
  }, [allCustomActivities, assignedCustomActivities]);

  useEffect(() => {
    setAssignedActivities(filterActivities(consultation.child.activityChildren, skill.id));
    setAssignedCustomActivities(filterActivities(consultation.child.customActivityChildren, skill.id, true));
  }, [consultation]);

  const [assignedActivities, setAssignedActivities] = useState<Consultation_consultation_child_activityChildren[]>([]);

  const { data } = useQuery<GetActivitiesFromPreskills, GetActivitiesFromPreskillsVariables>(
    PRESKILL_ACTIVITIES_QUERY,
    {
      variables: { preskillIds: [skill.id] },
    },
  );

  const [allActivities, setAllActivities] = useState<GetActivitiesFromPreskills_getActivitiesFromPreskills[]>([]);

  const [allAvailableActivities, setAllAvailableActivities] = useState<
    GetActivitiesFromPreskills_getActivitiesFromPreskills[]
  >([]);

  const [randomAvailableActivities, setRandomAvailableActivities] = useState<
    Array<GetActivitiesFromPreskills_getActivitiesFromPreskills | GetCustomActivitiesBySkillIdData_customActivities>
  >([]);

  useEffect(() => {
    if (data && data.getActivitiesFromPreskills && data.getActivitiesFromPreskills.length > 0) {
      setAllActivities(data?.getActivitiesFromPreskills?.filter((a) => a.mainSkill.id == skill.id));
    }
  }, [data]);

  useEffect(() => {
    setAllAvailableActivities(allActivities.filter((a) => !assignedActivities.some((b) => b.activity.id === a.id)));
  }, [allActivities, assignedActivities]);

  const [selectedActivityIndex, setSelectedActivityIndex] = useState(DEFAULT_INDEX);
  const [randomCount, setRandomCount] = useState(RANDOM_COUNT);
  const [isActivitySelectionOpen, setIsActivitySelectionOpen] = useState(false);

  const closeActivitySelection = () => {
    setSelectedActivityIndex(DEFAULT_INDEX);
    setRandomCount(RANDOM_COUNT);
    setIsActivitySelectionOpen(false);
  };

  const refreshRandomAvailableActivities = () => {
    setSelectedActivityIndex(DEFAULT_INDEX);
    setRandomAvailableActivities(getRandomElements(allAvailableMergedActivities, randomCount));
  };

  const [allAvailableMergedActivities, setAllAvailableMergedActivities] = useState<
    Array<GetActivitiesFromPreskills_getActivitiesFromPreskills | GetCustomActivitiesBySkillIdData_customActivities>
  >([]);

  useEffect(() => {
    setAllAvailableMergedActivities([...allAvailableActivities, ...allAvailableCustomActivities]);
  }, [allAvailableActivities, allAvailableCustomActivities]);

  useEffect(() => {
    refreshRandomAvailableActivities();
  }, [randomCount]);

  useEffect(() => {
    if (randomAvailableActivities.length == 1) setSelectedActivityIndex(0); // auto select activity if there is only 1 available activity
  }, [randomAvailableActivities]);

  const clickAddActivities = () => {
    refreshRandomAvailableActivities();
    setIsActivitySelectionOpen(true);
  };

  const viewMoreActivities = () => {
    setRandomCount(
      allAvailableMergedActivities.length > randomCount ? allAvailableMergedActivities.length : RANDOM_COUNT,
    );
  };

  const [viewActivity, setViewActivity] = useState<GetActivitiesFromPreskills_getActivitiesFromPreskills | null>(null);
  const [isActivityDetailsOpen, setIsActivityDetailsOpen] = useState(false);
  const [consultationActivity, setConsultationActivity] =
    useState<Consultation_consultation_child_activityChildren | null>(null);

  const [viewCustomActivity, setViewCustomActivity] =
    useState<Consultation_consultation_child_customActivityChildren_customActivity | null>(null);
  const [isCustomActivityDetailsOpen, setIsCustomActivityDetailsOpen] = useState(false);
  const [consultationCustomActivity, setConsultationCustomActivity] =
    useState<Consultation_consultation_child_customActivityChildren | null>(null);

  const openActivityDetails = (ac: Consultation_consultation_child_activityChildren) => {
    setConsultationActivity(ac);
    setViewActivity(allActivities.filter((a) => a.id == ac.activity.id)[0]);
    setIsActivityDetailsOpen(true);
  };

  const openCustomActivityDetails = (cac: Consultation_consultation_child_customActivityChildren) => {
    setConsultationCustomActivity(cac);
    setViewCustomActivity(cac.customActivity);
    setIsCustomActivityDetailsOpen(true);
  };

  const closeActivityDetails = async () => {
    setIsActivityDetailsOpen(false);
    setViewActivity(null);
  };

  const closeCustomActivityDetails = async () => {
    setIsCustomActivityDetailsOpen(false);
    setViewCustomActivity(null);
  };

  const redirectActivityRecommend = () => {
    window.open(t('therapist.consultationScreen.task.activityIdea.link'), '_blank');
  };

  const [addActivitiesToChild] = useMutation<AddActivitiesToChild, AddActivitiesToChildVariables>(
    ADD_ACTIVITIES_TO_CHILD,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
      onCompleted: () => {
        setTimeout(() => {
          setPollingCount((prevCount) => prevCount - 1);
        }, FAST_POLL);
      },
      onError: (error) => {
        console.error('Mutation error:', error);
      },
    },
  );

  const [addCustomActivitiesToChild] = useMutation<AddCustomActivitiesToChild, AddCustomActivitiesToChildVariables>(
    ADD_CUSTOM_ACTIVITIES_TO_CHILD,
    {
      refetchQueries: [{ query: CONSULTATION_QUERY, variables: { consultationId: consultation.id } }],
      onCompleted: () => {
        setTimeout(() => {
          setPollingCount((prevCount) => prevCount - 1);
        }, FAST_POLL);
      },
      onError: (error) => {
        console.error('Mutation error:', error);
      },
    },
  );

  const assignActivityToChild = async () => {
    setPollingCount((prevCount) => prevCount + 1);
    await addActivitiesToChild({
      variables: {
        childId: consultation.child.id,
        activityIds: [randomAvailableActivities[selectedActivityIndex].id],
        consultationId: consultation.id,
      },
    });
    closeActivitySelection();
  };

  const assignCustomActivityToChild = async (customActivityId: string | number) => {
    setPollingCount((prevCount) => prevCount + 1);
    const activityId = typeof customActivityId === 'string' ? parseInt(customActivityId, 10) : customActivityId;

    if (activityId) {
      await addCustomActivitiesToChild({
        variables: {
          childId: consultation.child.id,
          customActivityIds: [activityId],
          consultationId: consultation.id,
        },
      });
    }
    closeActivitySelection();
  };

  const Activities = () => {
    const renderActivityRows = (activities, isCustom) => {
      return activities.map((ac, index) => {
        const activity = isCustom ? ac.customActivity : ac.activity;
        const progressValue = isCustom
          ? consultation.child.assignedCustomActivities.find((a) => a.id === activity.id)?.customActivityCheckins
              ?.length ?? 0
          : consultation.child.assignedActivities.find((a) => a.id === activity.id)?.checkInCount ?? 0;
        const onClick = isCustom ? () => openCustomActivityDetails(ac) : () => openActivityDetails(ac);

        const removeComponent = isCPC ? (
          <RemoveActivityComponent
            activityToRemove={ac}
            isLongButton={false}
            closeActivityDetails={closeActivityDetails}
            isCustom={isCustom}
          />
        ) : null;

        return (
          <ActivityRow
            key={index}
            activity={activity}
            isCustom={isCustom}
            onClick={onClick}
            progressValue={progressValue}
            maxValue={MAX_ACTIVITY_CHECK_INS}
            removeComponent={removeComponent}
          />
        );
      });
    };

    return (
      <div>
        {renderActivityRows(assignedActivities, false)}
        {renderActivityRows(assignedCustomActivities, true)}
      </div>
    );
  };

  const AddActivityButton = () => {
    return (
      <RoundBorderButton onClick={clickAddActivities} style={{ height: `${BUTTON_HEIGHT}px` }}>
        {t('therapist.consultationScreen.activities.addActivities')} <BsPlusLg />
      </RoundBorderButton>
    );
  };

  return (
    <div>
      {isActivitySelectionOpen && (
        <PopUpDiv>
          <Col>
            <Row align="middle" justify="space-between">
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ marginRight: '1rem' }}>
                  <NumOfAvailableActivities totalAvailableActivities={allAvailableMergedActivities.length} />
                </div>
                <div>
                  <RefreshButton
                    totalAvailableActivities={allAvailableMergedActivities.length}
                    refreshRandomAvailableActivities={refreshRandomAvailableActivities}
                    randomCount={randomCount}
                  />
                </div>
                <div>
                  <ViewMoreActivities
                    totalAvailableActivities={allAvailableMergedActivities.length}
                    viewMoreActivities={viewMoreActivities}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SuggestMoreActivities skill={skill} assignCustomActivityToChild={assignCustomActivityToChild} />
                <div
                  style={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}
                  onClick={closeActivitySelection}
                >
                  <CloseXButton />
                </div>
              </div>
            </Row>

            <RandomActivityComponent
              randomAvailableActivities={randomAvailableActivities}
              setSelectedActivityIndex={setSelectedActivityIndex}
              selectedActivityIndex={selectedActivityIndex}
            />

            <RandomActivityConfirmOrSuggest
              totalAvailableActivities={allAvailableMergedActivities.length}
              selectedActivityIndex={selectedActivityIndex}
              selectedActivity={randomAvailableActivities[selectedActivityIndex]}
              assignActivityToChild={assignActivityToChild}
              assignCustomActivityToChild={assignCustomActivityToChild}
              redirectActivityRecommend={redirectActivityRecommend}
            />
          </Col>
        </PopUpDiv>
      )}

      <ActivityDetailsPopup
        isOpen={isActivityDetailsOpen || isCustomActivityDetailsOpen}
        closeDetails={isCustomActivityDetailsOpen ? closeCustomActivityDetails : closeActivityDetails}
        isCPC={isCPC}
        removeComponent={
          <RemoveActivityComponent
            activityToRemove={isCustomActivityDetailsOpen ? consultationCustomActivity : consultationActivity}
            isLongButton={true}
            closeActivityDetails={isCustomActivityDetailsOpen ? closeCustomActivityDetails : closeActivityDetails}
            isCustom={isCustomActivityDetailsOpen}
          />
        }
        viewComponent={
          isCustomActivityDetailsOpen && viewCustomActivity ? (
            <CustomActivityCard customActivity={viewCustomActivity} isSelected={true} />
          ) : (
            viewActivity && <ActivityCard activity={viewActivity} isSelected={true} />
          )
        }
      />

      {assignedActivities.length > 0 || assignedCustomActivities.length > 0 ? (
        <div>
          {isDropDown ? (
            <div>
              <Activities />
              <Row justify="end">
                <AddActivityButton />
              </Row>
            </div>
          ) : (
            <TotalCheckInsActivity
              assignedActivities={assignedActivities}
              assignedCustomActivities={assignedCustomActivities}
              consultation={consultation}
              handleDropDownClick={handleDropDownClick}
            />
          )}
        </div>
      ) : (
        isCPC && (
          <div>
            {curSkill?.mastered ? (
              <div style={{ opacity: '0.7', cursor: 'default', color: 'teal' }}>
                {`[${t('therapist.consultationScreen.activities.achieved')}]`}
              </div>
            ) : (
              <Row justify="space-between">
                <div style={{ opacity: '0.5', cursor: 'default' }}>
                  {`[${t('therapist.consultationScreen.activities.toBeAssigned')}]`}
                </div>
                <AddActivityButton />
              </Row>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default ActivityComponent;
