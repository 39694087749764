import gql from 'graphql-tag';

export const CHILD_QUERY = gql`
  query Child($childId: ID!) {
    child(childId: $childId) {
      id
      name
      gender
      dob
      pictureUrl
      consentFormCompleted
      therapist {
        id
        user {
          id
        }
      }
      client {
        id
        user {
          id
          name
          email
          locale
        }
      }
      assignedActivities {
        id
        name
        summary
        checkInCount
      }
      lastAssignedActivities {
        id
        name
        summary
        done
        checkInCount
      }
      assignedCustomActivities {
        id
        name
        skill {
          id
          name
        }
        customActivityAttachments {
          id
          attachmentType
          data
        }
        introduction
        parentInteraction
        createdAt
        customActivityCheckins {
          id
        }
      }
      lastAssignedCustomActivities {
        id
        name
        skill {
          id
          name
        }
        customActivityAttachments {
          id
          attachmentType
          data
        }
        introduction
        parentInteraction
        createdAt
        customActivityCheckins {
          id
        }
      }
      consultations {
        id
        consultationTime
        notes {
          id
          mainComplaint
          internalNotes
          commentForParents
        }
        goals {
          id
          description
          metric
          initNumerator
          initDenominator
          currentNumerator
          currentDenominator
          successNumerator
          successDenominator
        }
        goalRecords {
          id
          goal {
            id
            description
            initNumerator
            initDenominator
            successNumerator
            successDenominator
            metric
            skill {
              id
              category {
                id
                name
              }
            }
          }
          currentDenominator
          currentNumerator
        }
        activities {
          id
          name
          summary
          mainSkill {
            id
            name
            category {
              id
              name
            }
            subcategory {
              id
              name
            }
          }
          parentInteraction
        }
        canceled
        accepted
        published
        uploadedVideos {
          id
          consultation {
            id
          }
          uploadedVideoName
          isDownloaded
        }
      }
      room {
        id
      }
      nextConsultation {
        id
        consultationTime
      }
      previousConsultation {
        id
        consultationTime
        notes {
          id
          mainComplaint
          internalNotes
          commentForParents
        }
      }
      homeworks {
        id
        description
        submissionDate
        format
        homeworkSubmission {
          id
          message {
            id
            body
            user {
              id
              name
            }
            replies {
              id
              message {
                id
                body
                user {
                  id
                  name
                }
                createdAt
              }
            }
            createdAt
          }
          homeworkUrl
          homeworkContentType
        }
      }
    }
  }
`;
