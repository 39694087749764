import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { ClientContext } from '../../../../ClientContext';
import { useTranslation } from 'react-i18next';
import { CheckInButton } from '../styles';
import { ActionTitle } from '../../../../../../style';
import { AddIcon } from '../../../../WordTrackingRoute/ToolBox/AddButton/styles';
import { CUSTOM_ACTIVITY_CHECKIN_MUTATION } from '../../../../../../graphql/mutations/customActivityCheckin';
import {
  AddCheckinToCustomActivity,
  AddCheckinToCustomActivityVariables,
} from '../../../../../../graphql/mutations/types/AddCheckinToCustomActivities';

interface Props {
  customActivityId: string;
  handleCustomActivityCheckin: (customActivityIDs: Array<string>) => void;
}

const CheckinButton = ({ customActivityId, handleCustomActivityCheckin }: Props) => {
  const { t } = useTranslation();
  const { child, setReturnMessage } = useContext(ClientContext);
  const childId = child.id;
  const [addCheckinToCustomActivity, { loading, error, data }] = useMutation<
    AddCheckinToCustomActivity,
    AddCheckinToCustomActivityVariables
  >(CUSTOM_ACTIVITY_CHECKIN_MUTATION);

  // update message near header
  useEffect(() => {
    if (data && data.addCheckinToCustomActivity) {
      setReturnMessage(data.addCheckinToCustomActivity.returnMessage);
      setTimeout(() => {
        setReturnMessage(undefined);
      }, 3000);
    }
  }, [data]); // <-- only update when data is changed

  const handleCheckIn = async () => {
    await addCheckinToCustomActivity({
      variables: {
        childId,
        customActivityId: customActivityId,
      },
    });
    handleCustomActivityCheckin([customActivityId]);
  };

  return (
    <CheckInButton onClick={handleCheckIn}>
      <AddIcon className="plus" />
      <ActionTitle>{t('client.task.activities.overall.check')}</ActionTitle>
    </CheckInButton>
  );
};

export default React.memo(CheckinButton);
