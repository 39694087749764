import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Client_client_children_assignedCustomActivities } from '../../../../../../graphql/queries/types/Client';
import { MetricsNumber, MetricsTitle } from '../../../../../../style';
import { WeekDayTitle } from '../../ActivityPlan/styles';
import { FlexContainer, CheckInContainer, CheckInCountContainer, CheckInIndicator } from '../OverallTab/styles';

interface Props {
  customActivity: Client_client_children_assignedCustomActivities;
  currentWeekday: number;
}

const WeeklyCheckInView = ({ customActivity, currentWeekday }: Props) => {
  const { t } = useTranslation();
  const countDaily = customActivity.checkInCountDaily;
  const [total, showCustomActivityCount] = useMemo(() => {
    return [
      countDaily.slice(0, currentWeekday).reduce((a, b) => a + b, 0),
      countDaily
        .slice(0, currentWeekday)
        .reverse()
        .map((c, index) => (
          <CheckInCountContainer>
            <WeekDayTitle status={false}>{t(`client.task.activities.overall.${index + 1}`)}</WeekDayTitle>
            <CheckInIndicator status={c !== 0}>{c}</CheckInIndicator>
          </CheckInCountContainer>
        ))
        .concat(
          new Array(7 - currentWeekday).fill(0).map((c, index) => (
            <CheckInCountContainer>
              <WeekDayTitle status={false}>
                {t(`client.task.activities.overall.${currentWeekday + index + 1}`)}
              </WeekDayTitle>
            </CheckInCountContainer>
          )),
        ),
    ];
  }, [countDaily, currentWeekday, t]);

  return (
    <CheckInContainer>
      <MetricsTitle>{t(`client.task.activities.overall.dayRecord`)}</MetricsTitle>
      <FlexContainer style={{ marginBottom: '10px' }}>{showCustomActivityCount}</FlexContainer>
      <MetricsTitle>{t(`client.task.activities.overall.totalRecord`)}</MetricsTitle>
      <MetricsNumber>{total}</MetricsNumber>
    </CheckInContainer>
  );
};

export default React.memo(WeeklyCheckInView);
